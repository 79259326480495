<template>
  <v-container fluid>
    <v-card-title class="pa-4">
      <v-icon left>mdi-magnify</v-icon> Search Result for "{{ $route.query.q }}"</v-card-title
    >
    <v-card>
      <v-data-table
        v-show="documents.length > 0"
        :must-sort="true"
        :no-data-text="''"
        :headers="headers"
        :items="documents"
        disable-sort
        :loading="loading"
        :options.sync="tableOptions"
        :server-items-length="totalRecords"
        :footer-props="{
          itemsPerPageOptions: [25, 50, 75, 100, 500],
        }"
        @click:row="item => selectDocument(item)"
      >
        <template v-slot:item.icon="{}">
          <v-icon>mdi-file-document</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import searchService from "@/services/searchService";
import dataTableService from "@/services/dataTableService.js";
import { mapState } from "vuex";
import Auth0Mixin from "./Auth0Mixin";

export default {
  mixins: [Auth0Mixin],
  data() {
    return {
      staticColumnHeaders: [
        { text: "", value: "icon" },
        { text: "Doc #", value: "id" },
      ],
      headers: [],
      documents: [],
      loading: false,
      totalRecords: 0,
      tableOptions: {
        sortDesc: [false],
        itemsPerPage: 25,
        page: 1,
      },
    };
  },

  watch: {
    tableOptions: {
      handler() {
        this.doSearch();
      },
      deep: true,
    },

    "$route.query.q": async function() {
      this.tableOptions.page = 1;
      await this.doSearch();
    },
  },

  computed: {
    ...mapState("userProfile", ["dateFormat"]),
  },

  methods: {
    async init() {
      this.tableOptions.page = 1;
      await this.doSearch();
    },

    async doSearch() {
      this.documents = [];
      this.loading = true;

      let result = await searchService.search(this.$route.query.q, this.tableOptions);

      this.totalRecords = result.totalRecords;

      let translated = dataTableService.translateResultSet(result, this.dateFormat);
      this.headers = [...this.staticColumnHeaders, ...translated.headers];
      this.documents = translated.rows;

      this.loading = false;
    },

    selectDocument(item) {
      this.$router.push({ name: "Document", params: { id: item.id } });
    },
  },
};
</script>
