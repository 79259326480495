module.exports.highlight = (text, searchQuery) => {
  if (!text) {
    return "";
  }

  if (!searchQuery) {
    return text;
  }

  let lt = text.toLowerCase();
  let ls = searchQuery.toLowerCase().trim();
  let firstMatchIndex = lt.indexOf(ls);

  if (firstMatchIndex < 0) {
    return text;
  }

  let startPos = 0;
  let endPos = firstMatchIndex + ls.length + 500;

  if (endPos >= lt.length) {
    endPos = lt.length;
  }

  let snippet = text.substring(startPos, endPos);
  let snippetStartPos = snippet.toLowerCase().indexOf(ls);

  let result = snippet.substring(0, snippetStartPos);
  result += "<span class='highlight'>" + snippet.substring(snippetStartPos, snippetStartPos + ls.length) + "</span>";
  result += snippet.substring(snippetStartPos + ls.length, snippet.length);

  return result;
};

module.exports.filter = (rows, query, attribs) => {
  if (!query) return rows;

  let lowerQuery = query.toLowerCase();

  return rows.filter(f => {
    return (
      attribs.filter(a => {
        return f[a] && f[a].toLowerCase().indexOf(lowerQuery) >= 0;
      }).length > 0
    );
  });
};
