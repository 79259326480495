<template>
  <v-snackbar v-model="show" top center :color="color" :vertical="isAlert" :timeout="timeout" @click.native="clickHandler()">
    <div v-if="title">
      <b> {{ title }} </b>
    </div>
    {{ text }}
    <template v-slot:action="{ attrs }" v-if="isAlert">
      <v-btn color="indigo" text v-bind="attrs" @click="show = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data: () => ({
    show: false,
    text: "",
    title: null,
    isAlert: false,
    color: "info",
    vertical: false,
    timeout: 5000,
    clickHandler: () => {},
  }),
  methods: {
    message(text) {
      this.show = true;
      this.text = text;
      this.isAlert = false;
      this.title = null;
      this.timeout = 5000;
      this.color = "info";
      this.clickHandler = () => {};
    },
    success(text) {
      this.show = true;
      this.text = text;
      this.isAlert = false;
      this.title = null;
      this.timeout = 5000;
      this.color = "success";
      this.clickHandler = () => {};
    },
    error(text) {
      this.show = true;
      this.text = text;
      this.title = null;
      this.isAlert = false;
      this.timeout = 5000;
      this.color = "error";
      this.clickHandler = () => {};
    },
    exception(ex, showCloseButton = false) {
      this.show = true;
      this.text = ex.response.data.error.message;
      this.isAlert = showCloseButton;
      this.title = null;
      this.timeout = 5000;
      this.color = "error";
      this.clickHandler = () => {};
    },
    warn(text, title = null, isAlert = false) {
      this.show = true;
      this.text = text;
      this.title = title;
      this.isAlert = isAlert;
      this.timeout = isAlert ? -1 : 5000;
      this.color = "orange darken-2";
      this.clickHandler = () => {};
    },
    messageWithUrl(text, url) {
      this.show = true;
      this.text = text;
      this.isAlert = false;
      this.title = null;
      this.timeout = 5000;
      this.color = "info";
      this.clickHandler = () => {
        window.open(url, "_blank");
      };
    }
  },
};
</script>
