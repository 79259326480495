<template>
  <v-data-table
    disable-pagination
    :hide-default-footer="true"
    :must-sort="true"
    :no-data-text="'You have no unsigned division orders'"
    :headers="headers"
    :items="documents"
    :loading="loading"
    class="elevation-1"
    @click:row="item => selectDocument(item.id)"
  >
    <template v-slot:item.icon="{}">
      <v-icon>mdi-file-document</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import Auth0Mixin from "@/views/Auth0Mixin";
import documentRouteService from "@/services/documentRouteService";
import dataTableService from "@/services/dataTableService";

export default {
  mixins: [Auth0Mixin],

  data() {
    return {
      headers: [
        { text: "", value: "icon" },
        { text: "Division Order #", value: "docDivisionOrderNumber" },
        { text: "Purchaser", value: "docOwner" },
      ],
      documents: [],
      loading: true,
    };
  },

  methods: {
    selectDocument(id) {
      this.$router.push({ name: "Document", params: { id } });
    },

    async init() {
      let notReturnedDivisionOrders = await documentRouteService.getNotReturnedDivisionOrders();
      let translated = dataTableService.translateResultSet(notReturnedDivisionOrders, "");
      this.documents = translated.rows;
      this.loading = false;
    },
  },
};
</script>
