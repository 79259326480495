<template>
  <v-container fluid v-if="documentType">
    <v-card-title class="pa-4">
      <v-icon left>mdi-file-document</v-icon> {{ documentType.name }}s
      <v-spacer />
      <v-btn color="primary" @click="showUploadDialog = true" v-show="showSendButton" data-cy="send">
        <v-icon left>mdi-send</v-icon>
        Send {{ documentType.name }}
      </v-btn>
    </v-card-title>
    <div class="mb-2">
      <v-tabs
        background-color="transparent"
        :value="selectedDocumentRouteStatus"
        v-show="showDocumentRouteStatusTabs"
        @change="changeSelectedDocumentRouteStatus"
      >
        <v-tab :key="-1">All</v-tab>
        <v-tab v-for="ps in invoiceStatues" :key="ps.id">{{ ps.name }}</v-tab>
      </v-tabs>
    </div>
    <v-card>
      <v-data-table
        :must-sort="true"
        :no-data-text="''"
        :page.sync="page"
        :headers="headers"
        :sort-by.sync="sortyBy"
        :sort-desc.sync="sortDesc"
        :footer-props="{
          itemsPerPageOptions: [25, 50, 75, 100, -1],
        }"
        :items="documents"
        :loading="loading"
        :item-class="getItemClass"
        :search="searchQuery"
        @click:row="item => selectDocument(item)"
      >
        <template v-slot:item.icon="{}">
          <v-icon>mdi-file-document</v-icon>
        </template>

        <template v-slot:item.routeStatusId="{ item }">
          <DocumentRouteStatus :id="item.routeStatusId" />
        </template>
      </v-data-table>
    </v-card>

    <UploadDialog
      :show="showUploadDialog"
      :documentTypeId="documentType.id"
      :cancel="() => (showUploadDialog = false)"
    ></UploadDialog>
  </v-container>
</template>

<script>
import documentService from "@/services/documentService.js";
import documentRouteService from "@/services/documentRouteService.js";
import localStorageService from "@/services/localStorageService.js";
import filterService from "@/services/filterService.js";
import Auth0Mixin from "./Auth0Mixin";
import { mapState } from "vuex";
import UploadDialog from "@/dialogs/UploadDialog.vue";
import dataTableService from "@/services/dataTableService.js";
import documentRouteStatusList from "@/constants/documentRouteStatusList.js";
import documentRouteStatus from "@/constants/documentRouteStatus.js";
import DocumentRouteStatus from "@/components/DocumentRouteStatus";
import metadataKey from "@/constants/metadataKey";

export default {
  mixins: [Auth0Mixin],
  components: { UploadDialog, DocumentRouteStatus },
  data() {
    let state = localStorageService.get("sentDocuments");
    return {
      showUploadDialog: false,
      staticColumnHeaders: [
        { text: "", value: "actions", sortable: false },
        { text: "", value: "icon", sortable: false },
        { text: "Doc #", value: "id" },
      ],
      sortyBy: "id",
      sortDesc: true,
      headers: [],
      documents: [],
      searchQuery: state ? state.query : "",
      loading: true,
      highlight: filterService.highlight,
      documentType: undefined,
      documentRouteStatusList,
      page: 1,
      selectedDocumentRouteStatus: 0,
    };
  },

  watch: {
    searchQuery: function() {
      localStorageService.save("sentDocuments", { query: this.searchQuery });
    },
    "$route.params.documentTypeId": function() {
      this.init();
    },
    "$route.query.documentRouteStatusId": function() {
      this.init();
    },
  },

  methods: {
    getItemClass(item) {
      return item.isRead ? "read-document" : "unread-document";
    },

    changeSelectedDocumentRouteStatus(selected) {
      this.$router.push({ name: "DocumentList", query: { documentRouteStatusId: selected } });
    },

    async init() {
      let docTypeId = parseInt(this.$route.params.documentTypeId);
      this.documentType = this.documentTypes.find(dt => dt.id === docTypeId);
      this.selectedDocumentRouteStatus = parseInt(this.$route.query.documentRouteStatusId);
      this.page = 1;
      this.resultSet = await documentRouteService.getForDocumentType(docTypeId, this.selectedDocumentRouteStatus);

      let translated = dataTableService.translateResultSet(this.resultSet, this.dateFormat);
      this.headers = [...this.staticColumnHeaders, ...translated.headers].filter(
        h => h.value != metadataKey.DivisionOrderResponseStatus
      );
      this.documents = translated.rows;

      this.loading = false;
    },

    selectDocument(item) {
      this.$router.push({ name: "Document", params: { id: item.id } });
    },

    async resubmitDocument(item) {
      const clone = await documentService.resubmit(item.id);
      this.$router.push({ name: "Document", params: { id: `${clone.id}` } });
    },

    showResubmitButton(item) {
      return item.routeStatusId === documentRouteStatus.Rejected;
    },
  },

  computed: {
    ...mapState("userProfile", ["dateFormat"]),
    ...mapState("documentTypeStore", ["documentTypes"]),

    showDocumentRouteStatusTabs() {
      return this.documentType && this.documentType.id === 93;
    },
    invoiceStatues() {
      return documentRouteStatusList.filter(x => x.id !== documentRouteStatus.New);
    },

    showSendButton() {
      return this.showDocumentRouteStatusTabs;
    },
  },
};
</script>
<style>
.unread-document {
  font-weight: bold;
  cursor: pointer;
}
.read-document {
  background: #f5f7f7 !important;
}
</style>
