<template>
  <v-text-field
    @keypress="onlyAllowNumber($event)"
    :flat="isLineItem"
    :solo="isLineItem"
    :hide-details="isLineItem ? 'auto' : ''"
    :prepend-icon="isCurrency && isLineItem ? 'mdi-currency-usd' : ''"
    :append-icon="highlight && documentMetadata.autoExtracted ? 'mdi-auto-fix' : ''"
    ref="textField"
    v-bind:class="{ 'pt-4': !isLineItem }"
    type="number"
    :disabled="documentMetadata.metadata.isReadonly"
    v-model="documentMetadata.value"
    :label="label"
    :background-color="highlight ? HIGHLIGHT_BACKGROUND : 'transparent'"
    :data-cy="documentMetadata.metadata.key"
  ></v-text-field>
</template>

<script>
import valdationService from "@/services/validationService";
import metadataType from "@/constants/metadataType";
import colors from "@/constants/colors";
import documentMetadataService from "@/services/documentMetadataService";

export default {
  name: "NumericMetadata",
  props: {
    documentMetadata: Object,
    textSelection: Object,
    isLineItem: Boolean,
    focusOnLoad: Boolean,
    onClickToFill: Function,
  },

  data() {
    return {
      highlight: false,
      HIGHLIGHT_BACKGROUND: colors.HIGHLIGHT_BACKGROUND,
      onlyAllowNumber: valdationService.onlyAllowNumber,
    };
  },

  created() {
    if (this.documentMetadata.highlight) {
      this.highlight = true;
      setTimeout(() => {
        this.highlight = false;
        this.documentMetadata.autoExtracted = false;
      }, 2000);
    }

    documentMetadataService.focusIfRequested(this);
  },

  computed: {
    isCurrency() {
      return this.documentMetadata.metadata.metadataType.id === metadataType.Currency;
    },
    label() {
      return this.isLineItem ? "" : this.documentMetadata.metadata.name;
    },
  },

  watch: {
    textSelection() {
      // has focus?
      if (this.$refs.textField.$el.querySelectorAll("input")[0] !== document.activeElement) return;

      let value = valdationService.textToNumber(this.textSelection.text);
      this.documentMetadata.value = isNaN(parseFloat(value)) ? "" : parseFloat(value);

      documentMetadataService.assignTextSelection(this);

      if (this.onClickToFill) this.onClickToFill();
    },
    focusOnLoad() {
      documentMetadataService.focusIfRequested(this);
    },
  },
};
</script>
