<template>
  <v-app style="background: #f2f2f2">
    <AppNavigation v-if="isAuthenticated && showAppBar && showNavigation"></AppNavigation>
    <AppBar :show="showAppBar" />
    <v-main>
      <router-view />
    </v-main>
    <InputDialog ref="inputDialog"></InputDialog>
    <InputListDialog ref="inputListDialog"></InputListDialog>
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
    <Snackbar ref="snackbar"></Snackbar>
    <WelcomeDialog :close="closeWelcomeDialog" :show="showWelcomeDialog" />
    <img src="/img/powered-by.png" class="powered-by" />
  </v-app>
</template>

<script>
import Snackbar from "@/components/Snackbar";
import InputListDialog from "@/dialogs/InputListDialog";
import ConfirmDialog from "@/dialogs/ConfirmDialog";
import InputDialog from "@/dialogs/InputDialog";
import { getInstance } from "./auth";
import auth0Config from "@/auth0Config";
import logoService from "@/services/logoService";
import userService from "@/services/userService";
import companyService from "@/services/companyService";
import AppNavigation from "@/components/AppNavigation";
import AppBar from "@/components/AppBar";
import { API_URL, URL } from "@/env";
import { mapGetters } from "vuex";
import WelcomeDialog from "@/dialogs/WelcomeDialog";
import localStorageService from "@/services/localStorageService.js";
import logAuthException from "@/services/exceptionLoggingService";
import notificationService from "@/services/notificationService";
import notificationSessionService from "@/services/notificationSessionService";

export default {
  name: "App",

  components: {
    InputListDialog,
    InputDialog,
    Snackbar,
    AppNavigation,
    ConfirmDialog,
    WelcomeDialog,
    AppBar,
  },

  data() {
    return {
      API_URL,
      isUserAuthorized: false,
      showWelcomeDialog: false,
      auth0TokenPolling: null,
    };
  },

  computed: {
    showNavigation() {
      return this.$route.name && this.$route.name !== "Document";
    },

    showAppBar() {
      return (
        this.$route.name &&
        this.$route.name !== "LandingPage" &&
        this.$route.name.indexOf("Register") !== 0 &&
        this.$route.name.indexOf("Eula") !== 0 &&
        this.$route.name.indexOf("OnBoarding") !== 0 &&
        this.isUserAuthorized
      );
    },
    isAuthenticated: function() {
      return this.$auth && this.$auth.isAuthenticated && !this.$auth.loading;
    },
    ...mapGetters("auth0Store", ["isGlobalUser", "isOnBoardingPending", "selectedCompanyId"]),
  },
  methods: {
    async logout() {
      localStorageService.clear();
      await this.$auth.logout();
      const encodedUrl = encodeURIComponent(URL);
      window.location.href = `https://${auth0Config.domain}/v2/logout?client_id=${auth0Config.clientId}&returnTo=${encodedUrl}`;
    },
    async changePass() {
      await userService.resetPassword();
      this.$root.$snackbar.message("Check your inbox to reset your password");
    },
    naviateToUserProile() {
      this.$router.push({ name: "Profile" });
    },
    showAdmin() {
      this.$router.push({ path: "/admin/users" });
    },
    async closeWelcomeDialog(gotoSettings) {
      this.showWelcomeDialog = false;
      if (gotoSettings) {
        this.$router.push("/admin/settings/legalDocuments");
      }
    },
    auth0tokenRenewalPoll(seconds, auth0Instance) {
      let renewalInterval = (seconds-5)*1000;
      this.auth0TokenPolling = setInterval(async () => {
        let token = await auth0Instance.getTokenSilently();
        this.$store.dispatch("auth0Store/updateAuth0Token", { token });
      }, renewalInterval)
    }
  },
  watch: {
    selectedCompanyId() {
      notificationSessionService.setCompanyId(this.selectedCompanyId);
    },
  },
  async mounted() {
    this.$root.$snackbar = this.$refs.snackbar;
    this.$root.$inputListDialog = this.$refs.inputListDialog;
    this.$root.$inputDialog = this.$refs.inputDialog;
    this.$root.$confirmDialog = this.$refs.confirmDialog;
    const auth0Instance = getInstance();

    auth0Instance.$watch("loading", async loading => {
      if (!loading && auth0Instance.isAuthenticated) {
        let result = await auth0Instance.getTokenSilently({ detailedResponse: true });
        this.auth0tokenRenewalPoll(result.expires_in, auth0Instance);
        let token = result.access_token;
        this.$store.dispatch("auth0Store/updateAuth0Token", { token });

        let overriddenCompanyId = this.$route.query.selectedCompanyId;
        if (overriddenCompanyId) {
          this.$store.dispatch("auth0Store/updateCompanyId", { selectedCompanyId: overriddenCompanyId });
        }
        let companies = [];
        try {
          companies = await companyService.getAllCompanies();
          this.$store.dispatch("auth0Store/updateCompanies", companies);
        } catch (companyError) {
          logAuthException.logAuthException(companyError, "Retrieve user companies", "App.vue");
        }
        if (
          companies.length > 0 &&
          companies.every(c => c.isCloud) &&
          decodeURIComponent(window.location.href).includes("admin/invitations")
        ) {
          this.$store.dispatch("auth0Store/updateAuth0Initialized");
          this.isUserAuthorized = true;
          return;
        } else {
          try {
            const exchangeUser = await userService.getUserByExternalId(
              decodeURI(this.$auth.user.sub),
              overriddenCompanyId || this.selectedCompanyId
            );
            var unreadNotifications = await notificationService.getUnreadNotifications();
            this.$store.dispatch("userProfile/setUnreadNotification", unreadNotifications);
            await notificationSessionService.initialize(
              companies,
              this.$root.$snackbar.messageWithUrl,
              this.selectedCompanyId
            );

            if (!exchangeUser.user.companies.filter(x => !x.isCloud).length) {
              const hasCloudAccess = companies.some(x => x.isCloud);
              if (hasCloudAccess) {
                const cloudCompany = companies.filter(x => x.isCloud)[0];
                const url = cloudCompany.url;
                window.location.href = `${url}/repository`;
              } else {
                //TODO: document.write ?!
                document.write(this.$auth.user.email + " is not authorized to use this application.");
                const $auth = this.$auth;
                setTimeout(async () => {
                  alert(
                    `${this.$auth.user.email} is not authorized for this company. You will be now be logged out and will need to log back in with a different user`
                  );
                  await $auth.logout();
                }, 1500);
              }

              return;
            }
            // The user is Exchange user, so continue populating his Exchange application data
            this.isUserAuthorized = true;
            this.$store.dispatch("terminologyStore/updateTerminologies", { terminologies: exchangeUser.terminologies });
            this.$store.dispatch("documentTypeStore/updateDocumentTypes", { documentTypes: exchangeUser.documentTypes });

            this.$store.dispatch("auth0Store/updateUser", { user: exchangeUser.user });

            const profile = await userService.getUserPersonalization();
            this.$store.dispatch("userProfile/initUserProfile", profile.items);

            // This needs to be done last
            // All our views will wait for this flag to be true before rendering
            this.$store.dispatch("auth0Store/updateAuth0Initialized");
            if (this.isOnBoardingPending) {
              await this.$root.$confirmDialog.open("Sent Invitation", "You Sent an invitation. You need to wait for the company to accept or reject the invitation.", "Continue", { hideCancel: true });
            }

            this.showWelcomeDialog = exchangeUser.displayWelcomeMessage && this.$route.name === "Home";
          } catch (ex) {
            logAuthException.logAuthException(ex, "Failed login to Exchange", "App.vue");
            this.$router.push({ name: "ErrorLoading" });
          }
        }
        try {
          const logo = await logoService.getLogoAsBase64();
          this.$store.dispatch("logoStore/initLogo", logo);
        } catch (ex) {
          // fail silently when no logo
        }
      }
    });
  },
  beforeDestroy() {
    clearInterval(this.auth0TokenPolling);  
  },
};
</script>

<style>
.highlight {
  animation: pulse 1s 2;
  background: #f8f0b0;
}

.headline {
  color: #2bb2e7;
}

.global-user {
  border: 2px solid #d22;
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 0 !important;
  }
}

.docvue-background {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  top: 0;
}

.docvue-background img {
  width: 100%;
}

.powered-by {
  margin: 15px 15px 15px auto;
}
</style>
