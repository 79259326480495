<template>
  <v-flex>
    <div class="docvue-background">
      <img src="/img/landing-page.jpg" />
      <div class="display-1 text-center mt-4 pt-4">Please check your email for further instructions</div>
    </div>
  </v-flex>
</template>

<script>
export default {
  name: "RegisterSuccess",
  props: ["companyName"],
  data() {
    return {};
  },
};
</script>
