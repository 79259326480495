<template>
  <div>
    <v-toolbar color="transparent" elevation="0" dense prominent>
      <div v-show="pageCount > 1" class="mr-4 pr-4" style="user-select: none">
        <v-btn icon :disabled="currentPage === 1 || loading" @click="prevPage()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        {{ currentPage }} of {{ pageCount }}
        <v-btn icon :disabled="currentPage === pageCount || loading" @click="nextPage()">
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </div>
      <div v-if="!zoomDisabled">
        <v-btn :disabled="zoomOutDisabled" icon @click="zoomOut()"> <v-icon>mdi-magnify-minus</v-icon> </v-btn>
        <span :class="{ 'small-font': isMobile }">{{ zoomPercentage }}</span>
        <v-btn :disabled="zoomInDisabled" icon @click="zoomIn()"> <v-icon>mdi-magnify-plus</v-icon> </v-btn>
      </div>
      <v-btn :disabled="loading" @click="download" depressed class="ml-4">
        <v-icon left>mdi-download</v-icon> {{ downloadText }}</v-btn
      >
      <v-spacer></v-spacer>
      <slot name="actions"></slot>
    </v-toolbar>
    <div :id="`${this._uid}pdf-viewer`" class="pdf-viewer">
      <div class="text-center mt-4 pt-4" v-show="loading">
        <v-progress-circular :width="8" :size="80" indeterminate color="primary"></v-progress-circular>
      </div>
      <canvas v-show="!loading" :id="`${this._uid}pdf-canvas`" class="pdf-canvas elevation-4 ml-2"></canvas>
      <ClickToFillCanvas
        class="ml-2"
        v-if="!isReadonly"
        :documentId="id"
        :page="currentPage"
        :width="width"
        :height="height"
        :textSelected="textSelected"
        :workingMode="canvasWorkingMode"
      />
    </div>
  </div>
</template>
<script>
import ClickToFillCanvas from "./ClickToFillCanvas";
// import VueDragResize from "vue-drag-resize";
// import workingModeValues from "@/constants/documentCanvasWorkingMode";

//signatureImage
const pdfjsLib = window["pdfjs-dist/build/pdf"];
pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.10.377/build/pdf.worker.min.js";

import { mapGetters } from "vuex";
import responsiveService from "@/services/responsiveService";
import downloadService from "@/services/downloadService";

export default {
  props: {
    id: String,
    documentUrl: String,
    downloadUrl: String,
    textSelected: Function,
    isReadonly: Boolean,
    zoomDisabled: Boolean,
    canvasWorkingMode: String,
    signatureImage: String,
  },

  // components: { ClickToFillCanvas, VueDragResize },
  components: { ClickToFillCanvas },

  computed: {
    ...mapGetters("auth0Store", ["isGlobalUser", "selectedCompanyId", "auth0Token"]),
    viewerId() {
      return `${this._uid}pdf-canvas`;
    },
    canvasId() {
      return `${this._uid}pdf-viewer`;
    },
    isMobile() {
      return responsiveService.isMobile(this.$vuetify.breakpoint);
    },
    zoomPercentage() {
      return this.scale ? `${this.scale * 100} %` : "";
    },
    zoomOutDisabled() {
      return this.loading || this.scale <= 0.5;
    },
    zoomInDisabled() {
      return this.loading || this.scale >= 4;
    },
    downloadText() {
      return this.isMobile ? "" : "Download";
    },
  },

  data() {
    return {
      scale: 1.5,
      pageCount: 0,
      currentPage: 1,
      pdfDoc: null,
      width: undefined,
      height: undefined,
      loading: true,
      signaturePosition: {
        signWidth: 300,
        signHeight: 200,
        signTop: 100,
        signLeft: 100,
        page: 1,
      },
    };
  },

  async created() {
    this.loadAndRenderDocument();
  },

  methods: {
    async loadAndRenderDocument() {
      let params = {
        url: this.documentUrl,
        httpHeaders: {
          "Company-Id": this.selectedCompanyId,
          Authorization: `Bearer ${this.auth0Token}`,
        },
      };
      let loadingTask = pdfjsLib.getDocument(params);
      this.pdfDoc = await loadingTask.promise;
      this.pageCount = this.pdfDoc.numPages;
      this.currentPage = 1;
      this.renderPage();
    },
    resize(newRect) {
      this.signaturePosition.signWidth = newRect.width;
      this.signaturePosition.signHeight = newRect.height;
      this.signaturePosition.signTop = newRect.top;
      this.signaturePosition.signLeft = newRect.left;
    },
    renderPage() {
      this.loading = true;

      let canvas = document.getElementById(this.viewerId);

      let ctx = canvas.getContext("2d");

      this.pdfDoc.getPage(this.currentPage).then((page) => {
        var viewport = page.getViewport({ scale: this.scale });

        let width = screen.width < viewport.width ? screen.width - 100 : viewport.width;

        canvas.height = this.height = viewport.height;
        canvas.width = this.width = width;

        var renderContext = {
          canvasContext: ctx,
          viewport: viewport,
        };
        var renderTask = page.render(renderContext);
        renderTask.promise.then(() => (this.loading = false));
      });
    },

    zoomIn() {
      this.scale += 0.25;
      this.renderPage();
    },

    zoomOut() {
      this.scale -= 0.25;
      this.renderPage();
    },

    nextPage() {
      this.currentPage++;
      this.renderPage();
    },

    prevPage() {
      this.currentPage--;
      this.renderPage();
    },
    download() {
      downloadService.download(this.downloadUrl, this.selectedCompanyId, this.auth0Token);
    },
  },
  watch: {
    documentUrl() {
      this.loadAndRenderDocument();
    },
  },
};
</script>

<style scoped>
.pdf-viewer canvas {
  margin: 2rem;
  display: block;
  position: absolute;
}

.pdf-viewer {
  position: relative;
  margin: 0 auto;
  height: calc(100vh - 80px);
  width: 100%;
}

.imgback {
  background-color: rgba(200, 200, 82, 0.488);
  border: 1px solid rgb(16, 172, 233);
  width: 100%;
  height: 100%;
}
.fast {
  width: 100%;
  height: 100%;
}

.small-font {
  font-size: 10px;
}
</style>
