<template>
  <div class="org-select">
    <div class="metadata-label">{{ documentMetadata.metadata.name }}</div>
    <vselect
      ref="textField"
      v-model="documentMetadata.value"
      :options="orgUnits"
      label="label"
      :reduce="org => org.id"
      :data-cy="documentMetadata.metadata.key"
    />
  </div>
</template>

<script>
import orgUnitService from "@/services/admin/orgUnitService";
import Auth0Mixin from "../../views/Auth0Mixin";

export default {
  name: "OrgUnitMetadata",
  props: {
    documentMetadata: Object,
    focusOnLoad: Boolean,
    textSelection: Object,
    onClickToFill: Function,
  },
  mixins: [Auth0Mixin],

  data() {
    return {
      orgUnits: [],
    };
  },

  methods: {
    async init() {
      this.orgUnits = (await orgUnitService.getList(true)).items;
      this.orgUnits.forEach(o => (o.label = `${o.name} (${o.code})`));
    },
  },

  watch: {
    textSelection() {
      // has focus?
      if (this.$refs.textField.$refs.search !== document.activeElement) return;

      let q = this.textSelection.text.toLowerCase();

      let matchedOrg = this.orgUnits.find(
        o => o.name.toLowerCase().indexOf(q) >= 0 || o.code.toLowerCase().indexOf(q) >= 0
      );

      if (matchedOrg) {
        this.documentMetadata.value = matchedOrg.id;
        if (this.onClickToFill) this.onClickToFill();
      }
    },

    focusOnLoad() {
      if (this.focusOnLoad) {
        this.$refs.textField.$refs.search.focus();
      }
    },
  },

  async created() {
    if (this.documentMetadata.value) {
      this.documentMetadata.value = parseInt(this.documentMetadata.value);
    }
  },
};
</script>

<style>
.org-select ul {
  padding-left: 0 !important;
}
</style>
