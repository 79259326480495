export const roles = {
  Administrator: 1,
  User: 2,
  ReadOnly: 3,
};

export const metadataTypes = {
  CODING_METADATA_TYPE_IDS: [11, 12, 13, 14, 15],
  PARTY_METADATA_TYPE_IDS: [9, 18, 19, 21, 22],
  TEXT: 1,
  NUMERIC: 2,
  CURRENCY: 3,
  DATE: 4,
  DATETIME: 5,
  LIST: 6,
  USER: 7,
  DOCUMENT_TYPE: 8,
  VENDOR: 9,
  ORG_UNIT: 10,
  AFE: 11,
  COST_CENTER: 12,
  ACCOUNT_CODE: 13,
  DECK: 14,
  WELL: 15,
  APPROVAL_USER: 16,
  APPROVAL_GROUP: 17,
  OWNER: 18,
  OPERATOR: 19,
  PROPERTY: 20,
  BANK: 21,
  PARTY: 22,
};
