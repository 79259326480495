import httpService from "./httpService";

export default {
  search: (query, tableOptions) =>
    httpService.post("/search", {
      querySearchTerm: query,
      page: tableOptions.page || 1,
      pageSize: tableOptions.itemsPerPage,
    }),
};
