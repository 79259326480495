import { Bar } from "vue-chartjs";

export default {
	extends: Bar,
	props: {
		chartData: Array,
		chartLabels: Array,
		showLegend: Boolean,
	},
	async mounted() {

		const chart = {
			data: {
				datasets: this.chartData,
				labels: this.chartLabels,
			},
			options: {
				scales: {
					yAxes: [{
						stacked: true,
						ticks: {
							stepSize: 1,
						},
					}],
					xAxes: [{
						stacked: true,
						categoryPercentage: 1.0,
						type: "time",
						distribution: "series",
						time: { parser: "YYYY-MM-DDTHH:mm", tooltipFormat: "ll" },
						ticks: {
							source: "data",
							maxRotation: 90,
							minRotation: 90,
							autoSkip: false,
							stepSize: 1,
						},
					}]
				},
				legend: {
					display: this.showLegend
				},
			},
		};

		this.renderChart(chart.data, chart.options);
	},
};
