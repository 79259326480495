import httpService from "../httpService";

export default {
  uploadDocument: async (file, fileUploadDetails) =>
    httpService.postFile("/legaldocuments/upload", file, fileUploadDetails),
  updateDocument: async (id, fileUploadDetails) => httpService.put(`/legaldocuments/${id}/details`, fileUploadDetails),
  searchDocuments: async params => httpService.post("/legaldocuments/search", params),
  get: async id => httpService.get(`/legaldocuments/${id}`),
  deleteDocument: async id => httpService.deleteRequest(`/legaldocuments/${id}`),
};
