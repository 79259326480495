export default {
  download: (url, companyId, auth0Token, fileName) => {
    var xhr = new XMLHttpRequest();

    xhr.onreadystatechange = function() {
      if (this.readyState == 4 && this.status == 200) {
        let header = this.getResponseHeader("content-disposition");
        let downloadName = header?.split("filename=")[1].split(";")[0];
        let blobUrl = URL.createObjectURL(this.response);
        let link = document.createElement("a");
        link.target = "_blank";
        link.href = blobUrl;
        if (downloadName || fileName) link.download = downloadName || fileName;
        link.click();
      }
    };

    xhr.open("GET", url);
    xhr.setRequestHeader("Authorization", `Bearer ${auth0Token}`);
    xhr.setRequestHeader("Company-Id", companyId);
    xhr.responseType = "blob";
    xhr.send();
  },
};
