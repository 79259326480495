<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="pb-0">
        <v-spacer />

        <router-link to="legalDocument/create" v-slot="{ href, navigate }">
          <v-btn :href="href" @click="navigate" color="primary"> <v-icon left>mdi-upload</v-icon> Upload New </v-btn>
        </router-link>
      </v-card-title>
      <v-data-table
        disable-pagination
        :hide-default-footer="true"
        :must-sort="true"
        :no-data-text="''"
        :headers="headers"
        :items="documents"
        :loading="loading"
        :disable-sort="true"
        @click:row="item => editDocument(item)"
      >
        <template v-slot:item.insertDate="{ item }">
          <span>{{ new Date(item.insertDate).toLocaleString() }}</span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import Auth0Mixin from "@/views/Auth0Mixin";
import DocumentService from "@/services/admin/legalDocumentService";
import PrivacySettingConstants from "@/constants/privacySettings";
import LegalDocumentTypes from "@/constants/legalDocumentTypeList";

function documentTypeName(LegalDocumentTypes, documentTypeId) {
  return LegalDocumentTypes.find(x => x.id === documentTypeId).name;
}

export default {
  name: "LegalDocumentList",
  props: ["documentType"],
  mixins: [Auth0Mixin],
  data() {
    return {
      headers: [
        { text: "Upload Date", value: "insertDate" },
        { text: "Privacy", value: "privacySettingDescription" },
        { text: "Is Active", value: "isActive" },
        { text: "DocumentType", value: "documentType" },
        { text: "", value: "replace", width: "10%" },
      ],
      documents: [],
      loading: true,
      replaceDocumentData: {
        id: null,
        file: null,
      },
    };
  },
  methods: {
    async init() {
      await this.queryDocuments();
    },
    async queryDocuments() {
      this.loading = true;
      const result = await DocumentService.searchDocuments({});
      this.documents = result.items.map(x => ({
        ...x,
        documentType: documentTypeName(LegalDocumentTypes, x.legalDocumentTypeId),
        privacySettingDescription: PrivacySettingConstants.getById(x.privacySettingId).description,
      }));
      this.loading = false;
    },
    editDocument(document) {
      this.$router.push(`/admin/settings/legalDocument/${document.id}`);
    },
    async replaceDocument(id) {
      this.replaceDocumentData.id = id;
      this.$refs.replaceUpload.$refs.input.click();
    },
    async replaceFile(file) {
      try {
        if (!file) return;
        await DocumentService.replaceDocument(file, this.replaceDocumentData.id);
        this.replaceDocumentData = { file: null, id: null };
        this.$root.$snackbar.message("Successfuly replaced the document");
        this.queryDocuments();
      } catch (e) {
        this.$root.$snackbar.error("Cannot upload the new document. Contant support.");
      }
    },
  },
};
</script>
