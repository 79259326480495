<template>
  <div>
    <v-list three-line color="transparent">
      <template v-for="(version, index) in versions">
        <v-divider v-if="index > 0" :key="`divider-${version.id}`" inset></v-divider>

        <v-list-item :key="`version-${version.id}`">
          <v-list-item-avatar rounded color="grey lighten-2">
            {{ versions.length - index }}
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ getTitle(version, versions.length - index) }}</v-list-item-title>
            <v-list-item-subtitle>{{ getDate(version) }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
import dateService from "@/services/dateService";
import { mapState } from "vuex";

export default {
  name: "DocumentHistory",
  props: {
    versions: Array,
  },
  components: {},

  data() {
    return {
      selectedView: 0,
    };
  },

  computed: {
    ...mapState("userProfile", ["dateFormat"]),
  },

  methods: {
    getTitle(version, index) {
      return version.id === version.latestVersionDocId ? "Latest Version" : `Version ${index}`;
    },
    getDate(version) {
      return dateService.formatDateTime(version.updateDate, this.dateFormat);
    },
  },
};
</script>
