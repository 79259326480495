import httpService from "./httpService";

// Only allow
// 1 System Administrator
// 3 User
// 11 Delete Document (additional role)
const ENABLED_ROLES = [1, 3, 11];

export default {
  getAll: async () => {
    let allRoles = await httpService.get(`/lookups/roles`);
    return allRoles.filter(r => ENABLED_ROLES.indexOf(r.id) >= 0);
  },
};
