export default {
  namespaced: true,

  state: {
    isBusy: false,
  },

  mutations: {
    isBusy: (state, isBusy) => (state.isBusy = isBusy),
  },
};
