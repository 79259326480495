import { Line } from "vue-chartjs";

const dataSetOptions = {
  data: [],
  fill: false,
  label: "",
  pointRadius: 5,
  pointHoverRadius: 20,
  borderWidth: 3,
  borderColor: "#4facd1",
  pointBackgroundColor: "#4facd1",
};

const defaultLabel = (item, data) => {
  var label = data.datasets[item.datasetIndex].label || "";
  if (label) {
    label += ": ";
  }

  return `${label} ${item.value}`;
};

export default {
  extends: Line,
  props: {
    chartData: Array,
    label: Function,
  },

  async mounted() {
    const chart = {
      data: {
        datasets: [],
      },
      options: {
        borderColor: "#000",
        legend: { display: false },
        scales: {
          xAxes: [
            {
              type: "time",
              distribution: "series",
              time: { parser: "YYYY-MM-DDTHH:mm", tooltipFormat: "ll" },
              ticks: {
                source: "data",
                maxRotation: 90,
                minRotation: 90,
                autoSkip: false,
                stepSize: 1,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: 1,
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: this.label || defaultLabel,
          },
        },
      },
    };

    const datasets = this.chartData.map(s => ({ ...dataSetOptions, ...s }));
    const data = { ...chart.data, datasets };

    this.renderChart(data, chart.options);
  },
};
