<template>
  <canvas
    class="click-to-fill-canvas"
    ref="clickToFillCanvas"
    @mousedown.prevent="mousedown"
    @mousemove.prevent="mousemove"
    @mouseup.prevent="mouseup"
  ></canvas>
</template>

<script>
import documentPageOcrService from "@/services/documentPageOcrService.js";
import workingModeValues from "@/constants/documentCanvasWorkingMode";

const SELECTED_TEXT_COLOR = "#dddc16";
const SIGNATURE_CAPTURE_COLOR = "#af1ebd";
const ALPHA = 0.6;
const SELECTED_COLOR = "#f2f2ab";

export default {
  props: {
    width: Number,
    height: Number,
    textSelected: Function,
    documentId: String,
    workingMode: String,
    page: Number,
  },

  data() {
    return {
      isClickToFillMode: true,
      clickToFillSelection: {
        status: "none",
        relX: 0,
        relY: 0,
        relXEnd: 0,
        relYEnd: 0,
      },
    };
  },
  methods: {
    drawPolygons(polygons) {
      let width = this.$refs.clickToFillCanvas.width;
      let height = this.$refs.clickToFillCanvas.height;
      let context = this.$refs.clickToFillCanvas.getContext("2d");

      context.clearRect(0, 0, context.canvas.width, context.canvas.height);

      context.globalAlpha = ALPHA;
      context.fillStyle = SELECTED_TEXT_COLOR;

      for (let i = 0; i < polygons.length; i++) {
        context.beginPath();
        // Paint the polygon
        for (let j = 0; j < polygons[i].Coordinates.length; j++) {
          let coord = polygons[i].Coordinates[j];

          if (j === 0) {
            context.moveTo(coord.xRel * width, coord.yRel * height);
          } else {
            context.lineTo(coord.xRel * width, coord.yRel * height);
          }
        }
        context.closePath();
        context.fill();
      }

      setTimeout(function() {
        context.clearRect(0, 0, context.canvas.width, context.canvas.height);
      }, 1500);
    },

    drawClickToFillSelection() {
      let width = this.$refs.clickToFillCanvas.width;
      let height = this.$refs.clickToFillCanvas.height;

      let context = this.$refs.clickToFillCanvas.getContext("2d");
      context.clearRect(0, 0, context.canvas.width, context.canvas.height);

      context.globalAlpha = ALPHA;
      context.fillStyle =
        this.workingMode === workingModeValues.CAPTURE_SIGNATURE ? SIGNATURE_CAPTURE_COLOR : SELECTED_COLOR;
      let x = this.clickToFillSelection.relX * width;
      let y = this.clickToFillSelection.relY * height;
      let selectionWidth = this.clickToFillSelection.relXEnd * width - x;
      let selectionHeight = this.clickToFillSelection.relYEnd * height - y;

      context.fillRect(x, y, selectionWidth, selectionHeight);
    },

    mousedown(event) {
      this.clickToFillSelection.status = "click";

      let x = event.offsetX;
      let y = event.offsetY;

      let width = this.$refs.clickToFillCanvas.width;
      let height = this.$refs.clickToFillCanvas.height;

      let relX = x / width;
      let relY = y / height;

      this.clickToFillSelection.relX = relX;
      this.clickToFillSelection.relY = relY;
      if (this.workingkMode === workingModeValues.CAPTURE_SIGNATURE) return;
      let annotation = documentPageOcrService.findText(this.documentId, this.page, relX, relY);

      if (!annotation) {
        return;
      }

      this.drawPolygons([annotation]);

      if (this.textSelected) this.notifySelection(annotation.Text.trim(), relX, relY);
    },
    mousemove(event) {
      if (!this.isClickToFillMode || this.clickToFillSelection.status === "none") {
        return;
      }
      this.clickToFillSelection.status = "dragging";

      let x = event.offsetX;
      let y = event.offsetY;

      let width = this.$refs.clickToFillCanvas.width;
      let height = this.$refs.clickToFillCanvas.height;

      this.clickToFillSelection.relXEnd = x / width;
      this.clickToFillSelection.relYEnd = y / height;

      this.drawClickToFillSelection();
    },
    mouseup() {
      if (!this.isClickToFillMode) {
        return;
      }

      if (this.clickToFillSelection.status !== "dragging") {
        this.clickToFillSelection.status = "none";
        return;
      }

      let relWidth = Math.abs(this.clickToFillSelection.relX - this.clickToFillSelection.relXEnd);
      let relHeight = Math.abs(this.clickToFillSelection.relY - this.clickToFillSelection.relYEnd);

      let relX = Math.min(this.clickToFillSelection.relX, this.clickToFillSelection.relXEnd);
      let relY = Math.min(this.clickToFillSelection.relY, this.clickToFillSelection.relYEnd);
      if (this.workingkMode === workingModeValues.CAPTURE_SIGNATURE) {
        this.notifySelection(null, relX, relY, relWidth, relHeight);
        return;
      }
      let result = documentPageOcrService.findTextPoly(this.documentId, this.page, relX, relY, relWidth, relHeight);

      let text = "";

      for (let i = 0; i < result.length; i++) {
        text += result[i].Text + " ";
      }

      this.notifySelection(text.trim(), relX, relY, relWidth, relHeight);

      this.clickToFillSelection.status = "none";
      this.drawPolygons(result);
    },

    notifySelection(text, relX, relY, relWidth, relHeight) {
      let coordinates = { page: this.page, x: relX, y: relY, width: relWidth, height: relHeight };
      if (this.textSelected) this.textSelected(text ? text.trim() : null, coordinates);
    },
  },

  async mounted() {
    await documentPageOcrService.load(this.documentId, this.page);
  },

  watch: {
    async page() {
      await documentPageOcrService.load(this.documentId, this.page);
    },

    width() {
      document.getElementsByClassName("click-to-fill-canvas")[0].width = this.width;
    },

    height() {
      document.getElementsByClassName("click-to-fill-canvas")[0].height = this.height;
    },
  },
};
</script>

<style scoped>
.click-to-fill-canvas {
  top: 0;
}
</style>
