import metadataType from "@/constants/metadataType";
import _ from "lodash";
import moment from "moment";
import documentPageOcrService from "@/services/documentPageOcrService.js";
import formattingService from "@/services/formattingService.js";

export default {
  focusIfRequested: component => {
    if (!component.focusOnLoad) return;

    setTimeout(() => {
      component.$refs.textField.focus();
    }, 300);
  },

  assignTextSelection: component => {
    component.documentMetadata.clickX = component.textSelection.coordinates.x;
    component.documentMetadata.clickY = component.textSelection.coordinates.y;
    component.documentMetadata.clickWidth = component.textSelection.coordinates.width;
    component.documentMetadata.clickHeight = component.textSelection.coordinates.height;
    component.documentMetadata.clickPage = component.textSelection.coordinates.page;

    component.highlight = true;
    setTimeout(() => (component.highlight = false), 2000);
  },

  clearAllValues: documentMetadataList => {
    documentMetadataList.forEach(dm => {
      if (dm.metadata.isReadonly) return;

      dm.value = null;
      dm.lookupValue = null;
    });
  },

  detailItemTotal(rows) {
    let result = 0;
    for (let row of rows) {
      for (let cell of row.cells) {
        if (cell.metadata.isDollarTotal && cell.value) {
          result += parseFloat(cell.value);
        }
      }
    }

    return result;
  },

  getColumnWidthForMetadata(metadata) {
    let mt = metadata.metadataType.id;

    if (mt === metadataType.Date) return "200px";
    if (mt === metadataType.Text) return "200px";

    return "200px";
  },

  format(metadata, value, dateFormat) {
    let metadataTypeId = Number.isInteger(metadata) ? metadata : metadata.metadataType.id;

    if (metadataTypeId === metadataType.Date) {
      return value ? moment(value).format(dateFormat.toUpperCase()) : "";
    } else if (metadataTypeId === metadataType.Currency) {
      return formattingService.formatCurrency(value);
    } else if (metadataTypeId === metadataType.DateTime) {
      return moment(value).format(dateFormat.toUpperCase() + " hh:mm:ss A");
    } else if (metadataTypeId === metadataType.List) {
      let lv = _.find(metadata.lookupValues, function(lv) {
        return lv.id == value;
      });
      return lv ? lv.lookupValue : value;
    }

    return value;
  },

  hasAnyLineItems(rows) {
    return _.some(rows, row => {
      return _.some(row.cells, cell => {
        return !!cell.value;
      });
    });
  },

  populateAutoExtracted: async (documentId, documentMetadata, autoPopulatedMetadata) => {
    const textToNumber = args =>
      args
        .replace(/\$/g, "")
        .replace(/,/g, "")
        .replace(/[^0-9\-.]+/g, "");

    let distinctPages = _.uniq(
      _.map(autoPopulatedMetadata, function(r) {
        return r.clickPage;
      })
    );

    await documentPageOcrService.loadPagesIntoCache(documentId, distinctPages);

    _.each(autoPopulatedMetadata, function(r) {
      let textArray = documentPageOcrService.findTextPoly(
        documentId,
        r.clickPage,
        r.clickX,
        r.clickY,
        r.clickWidth,
        r.clickHeight
      );

      let text = _.map(textArray, function(item) {
        return item.Text;
      }).join(" ");

      let existingField = _.find(documentMetadata, function(m) {
        return m.metadata.id === r.metadata.id;
      });

      if (existingField && !existingField.value && text && text.length > 0) {
        if (existingField.value || existingField.isReadOnly || existingField.metadata.isReadonly) return;

        let metadataTypeId = existingField.metadata.metadataType.id;

        if (metadataTypeId === metadataType.Currency || metadataTypeId === metadataType.Numeric) {
          existingField.value = textToNumber(text);
        } else if (metadataTypeId === metadataType.Date) {
          let date = moment(text);
          if (!date.isValid()) {
            return;
          }

          existingField.value = date.format("YYYY-MM-DD");
        } else {
          existingField.value = text;
        }

        existingField.highlight = true;
        existingField.autoExtracted = true;
        existingField.clickHeight = r.clickHeight;
        existingField.clickPage = r.clickPage;
        existingField.clickWidth = r.clickWidth;
        existingField.clickX = r.clickX;
        existingField.clickY = r.clickY;
      }
    });
  },
};
