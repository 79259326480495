import httpService from "@/services/httpService";

export default {
  getLogoAsBase64: async () => {
    let response = await httpService.getBinary("/logo");
    const base64 = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ""));

    return "data:;base64," + base64;
  },
};
