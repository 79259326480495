const LOCAL_STORAGE_PREFIX = "dovcue.exchange.page";

const getLocalStorageKey = key => `${LOCAL_STORAGE_PREFIX}.${key}`;

export default {
  save: (key, options, additionalSearch) => {
    let hash = { ...options, ...additionalSearch };
    localStorage.setItem(getLocalStorageKey(key), JSON.stringify(hash));
  },

  get: (key, defaultOptions) => {
    let localStorageKey = getLocalStorageKey(key);
    let existing = localStorage.getItem(localStorageKey);
    if (!existing) {
      return defaultOptions;
    }

    return JSON.parse(existing);
  },

  clear: () => {
    localStorage.clear();
  },
};
