const href = document.location.href;

export const isLocalDevelopment = href.indexOf("http://localhost") === 0 || href.indexOf("https://localhost") === 0;
const isDevServer = href.indexOf("dev.") > 0;
const isQA = href.indexOf("qa.") > 0;
const isPreProd = href.indexOf("pre.") > 0;
const isUat = href.indexOf("uat.") > 0;
const isAuto = href.indexOf("auto.") > 0;

const isProd = !isDevServer && !isQA && !isLocalDevelopment && !isPreProd && !isUat && !isAuto;

let url = "";
let apiUrl = "";
let cloudUrl = "";

if (isLocalDevelopment) {
  url = "http://localhost:8000";
  apiUrl = `http://localhost:45000/api`;
  cloudUrl = "localhost:45001/repository";
}

if (isDevServer) {
  url = `${global.location.origin}`;
  apiUrl = "https://dev-docvuecloud.com/api";
  cloudUrl = "dev-docvuecloud.com/repository";
}

if (isQA) {
  url = `${global.location.origin}`;
  apiUrl = "https://qa-docvuecloud.com/api";
  cloudUrl = "qa-docvuecloud.com/repository";
}

if (isPreProd) {
  url = `${global.location.origin}`;
  apiUrl = "https://pre-docvuecloud.com/api";
  cloudUrl = "pre-docvuecloud.com/repository";
}

if (isUat) {
  url = `${global.location.origin}`;
  apiUrl = "https://uat-docvuecloud.com/api";
  cloudUrl = "uat-docvuecloud.com/repository";
}

if (isAuto) {
  url = `${global.location.origin}`;
  apiUrl = "https://auto-docvuecloud.com/api";
  cloudUrl = "auto-docvuecloud.com/repository";
}

if (isProd) {
  url = `${global.location.origin}`;
  apiUrl = "https://docvuecloud.com/api";
  cloudUrl = "docvuecloud.com/repository";
}

export const API_URL = apiUrl;
export const URL = url;
export const CLOUD_URL = cloudUrl;

export let environment;
if (isLocalDevelopment || isDevServer) {
  environment = "dev";
} else if (isQA) {
  environment = "qa";
} else if (isPreProd) {
  environment = "pre";
} else if (isUat) {
  environment = "uat";
} else if (isAuto) {
  environment = "auto";
} else {
  environment = "prod";
}
