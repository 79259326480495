<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    @keydown.esc="cancel"
    :persistent="options.persistent"
    v-bind:style="{ zIndex: options.zIndex }"
  >
    <v-card>
      <v-card-text class="title pt-4" v-show="!!message">{{ message }}</v-card-text>
      <v-card-text class="title pt-5" v-show="!!header">
        {{ header }}
      </v-card-text>
      <v-list v-show="listOptions.length > 0" class="pb-4">
        <v-list-item-group color="primary" v-model="selectedListOption">
          <v-list-item v-for="(item, i) in listOptions" :key="i" @click="selectListOption({ index: i, ...item })">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-card-actions class="pt-0" v-show="listOptions.length === 0">
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click.native="cancel">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    header: "",
    selectedListOption: null,
    listOptions: [],
    options: {
      color: "red",
      width: 390,
      zIndex: 200,
    },
  }),
  methods: {
    open(message, options) {
      this.dialog = true;
      this.message = message;
      this.listOptions = [];
      this.selectedListOption = null;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    showListOptions(message, listOptions, header, options) {
      this.dialog = true;
      this.message = message;
      this.selectedListOption = null;
      this.header = header;
      this.options = Object.assign(this.options, options);
      this.listOptions = listOptions;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    selectListOption(item) {
      this.resolve(item);
      this.dialog = false;
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
