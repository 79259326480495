export default {
  namespaced: true,

  state: {
    documentTypes: undefined,
  },

  mutations: {
    mutateDocumentTypes: (state, documentTypes) => (state.documentTypes = documentTypes),
  },
  actions: {
    updateDocumentTypes({ commit }, { documentTypes }) {
      commit("mutateDocumentTypes", documentTypes);
    },
  },
};
