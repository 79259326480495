<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="pb-0">
        <v-icon left>person</v-icon>
        Users
        <v-spacer />
        <v-btn color="primary" @click="addNew" class="ml-4"> <v-icon>mdi-plus</v-icon> New User </v-btn>
      </v-card-title>

      <div class="ml-4 mb-4">
        <v-text-field
          v-model="searchQuery"
          prepend-icon="mdi-magnify"
          :label="searchLabel"
          single-line
          hide-details
          class="mr-4"
        ></v-text-field>
      </div>

      <v-data-table
        disable-pagination
        :hide-default-footer="true"
        :must-sort="true"
        :no-data-text="''"
        :headers="headers"
        :items="users"
        class="ma-1"
        :options.sync="tableOptions"
        :search="searchQuery"
        :loading="loading"
        @click:row="item => selectUser(item)"
      >
        <template v-slot:item.icon="{}">
          <v-icon>person</v-icon>
        </template>

        <template v-slot:item.emailAddress="{ item }">
          <div v-bind:class="{ inactive: !item.isActive }" v-html="highlight(item.emailAddress, searchQuery)"></div>
        </template>

        <template v-slot:item.firstName="{ item }">
          <div v-html="highlight(item.firstName, searchQuery)"></div>
        </template>

        <template v-slot:item.lastName="{ item }">
          <div v-html="highlight(item.lastName, searchQuery)"></div>
        </template>

        <template v-slot:item.isActive="{ item }">
          {{ item.isActive ? "yes" : "no" }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import userService from "@/services/userService";
import Auth0Mixin from "../Auth0Mixin";
import filterService from "@/services/filterService.js";
import searchUtilsService from "@/services/searchUtilsService.js";
import localStorageService from "@/services/localStorageService.js";

export default {
  name: "UserList",
  mixins: [Auth0Mixin],
  data() {
    let state = localStorageService.get("usersList");

    return {
      headers: [
        { text: "", value: "icon", width: 40, sortable: false },
        { text: "Email", value: "emailAddress" },
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "Active", value: "isActive" },
      ],
      tableOptions: {
        sortBy: ["emailAddress"],
        sortDesc: [false],
      },
      users: [],
      loading: true,
      searchQuery: state ? state.query : "",
      highlight: filterService.highlight,
    };
  },

  watch: {
    searchQuery: function() {
      localStorageService.save("usersList", { query: this.searchQuery });
    },
  },

  computed: {
    searchLabel: function() {
      return searchUtilsService.getLabel("Users", this.users);
    },
  },

  methods: {
    // This method will be called after auth0 is done the handshake
    async init() {
      this.users = await userService.getUserList();
      this.loading = false;
    },

    getStatusColor(user) {
      return user.blocked ? "5px solid #b22" : "";
    },

    async addNew() {
      this.$router.push(`/admin/user/create`);
    },

    selectUser(user) {
      this.$router.push(`/admin/user/${user.id}`);
    },
  },
};
</script>
<style scoped>
.inactive {
  text-decoration: line-through;
}
</style>
