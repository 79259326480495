<template>
  <v-container fluid v-if="addressSet">
    <v-card class="elevation-1 card pa-4 card-form">
      <LoadingProgress :loading="loading" />
      <v-form ref="form">
        <v-card-text>
          <v-row>
            <h3 class="headline mb-4">{{ isCreate ? "Create" : "Edit" }} {{ "Address Set" }}</h3>
            <v-spacer />
            <div v-show="!isCreate">
              <ActionDropdown :actions="actions" :performAction="performAction" />
            </div>
          </v-row>
          <EntityForm :entity="addressSet" :inputFields="ADDRESS_SET_FIELDS">
            <v-data-table
              v-model="addressSet.selectedParties"
              :headers="headers"
              :items="enhancedParties"
              item-key="id"
              show-select>
            </v-data-table>
          </EntityForm>
        </v-card-text>
      </v-form>

      <v-card-actions>
        <v-spacer />
        <v-btn @click="performAction('Save')" :disabled="loading" large color="primary" depressed>Save</v-btn>
        <v-btn @click="cancel()" :disabled="loading" large depressed>Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import addressSetService from "@/services/admin/addressSetService";
import partyService from "@/services/partyService";
import partyStatus from "@/constants/partyStatus";
import EntityForm from "@/components/EntityForm";
import ActionDropdown from "@/components/ActionDropdown";
import LoadingProgress from "@/components/LoadingProgress";
import Auth0Mixin from "../Auth0Mixin";
import { mapGetters } from "vuex";

const ACTION_DELETE = "Delete";
const ACTION_SAVE = "Save";

const requiredRule = [v => !!v || "Required"];

export default {
  name: "AddressSet",
  mixins: [Auth0Mixin],
  components: { EntityForm, ActionDropdown, LoadingProgress },
  data() {
    return {
      addressSet: {
        name: "",
        coder: "",
        isActive: true,
        mainAddressLineOne: "",
        mainAddressLineTwo: "",
        mainAddressCity: "",
        mainAddressState: "",
        mainAddressZipCode: "",
        paymentAddressLineOne: "",
        paymentAddressLineTwo: "",
        paymentAddressCity: "",
        paymentAddressState: "",
        paymentAddressZipCode: "",
        paymentAddressSameAsMain: false,
        taxAddressLineOne: "",
        taxAddressLineTwo: "",
        taxAddressCity: "",
        taxAddressState: "",
        taxAddressZipCode: "",
        taxAddressSameAsMain: false,
        selectedParties: [],
      },
      ADDRESS_SET_FIELDS: [
        { label: "Name", attribute: "name", rules: requiredRule, type: "text" },
        { label: "Main Address", type: "header" },
        { label: "Address Line One", attribute: "mainAddressLineOne", rules: requiredRule, type: "text" },
        { label: "Address Line Two", attribute: "mainAddressLineTwo", type: "text" },
        { label: "City", attribute: "mainAddressCity", rules: requiredRule, type: "text" },
        { label: "State", attribute: "mainAddressState", rules: requiredRule, type: "text" },
        { label: "Zip Code", attribute: "mainAddressZipCode", rules: requiredRule, type: "text" },
        { label: "Payment Address", type: "header" },
        { label: "Addressee", attribute: "paymentAddresseeName", type: "text" },
        { label: "Address Line One", attribute: "paymentAddressLineOne", rules: requiredRule, type: "text" },
        { label: "Address Line Two", attribute: "paymentAddressLineTwo", type: "text" },
        { label: "City", attribute: "paymentAddressCity", rules: requiredRule, type: "text" },
        { label: "State", attribute: "paymentAddressState", rules: requiredRule, type: "text" },
        { label: "Zip Code", attribute: "paymentAddressZipCode", rules: requiredRule, type: "text" },
        {
          label: "Same as Main Address",
          attribute: "paymentAddressSameAsMain",
          type: "checkbox",
          click: this.paymentAddressSameAsMainClick,
        },
        { label: "Tax Address", type: "header" },
        { label: "Addressee", attribute: "taxAddresseeName", type: "text" },
        { label: "Address Line One", attribute: "taxAddressLineOne", rules: requiredRule, type: "text" },
        { label: "Address Line Two", attribute: "taxAddressLineTwo", type: "text" },
        { label: "City", attribute: "taxAddressCity", rules: requiredRule, type: "text" },
        { label: "State", attribute: "taxAddressState", rules: requiredRule, type: "text" },
        { label: "Zip Code", attribute: "taxAddressZipCode", rules: requiredRule, type: "text" },
        {
          label: "Same as Main Address",
          attribute: "taxAddressSameAsMain",
          type: "checkbox",
          click: this.taxAddressSameAsMainClick,
        },
        { label: "Share with Parties", type: "header" },
      ],
      parties: [],
      alreadySelectedParties: [],
      headers: [
        { text: "Name", value: "name" },
        { text: "Number", value: "number", sortable: false },
      ],
      isDirty: false,
      isCreate: false,
      loading: false,
      actions: [ACTION_SAVE, ACTION_DELETE],
      requiredRules: [v => !!v || "Required"],
    };
  },

  methods: {
    async init() {
      this.loading = true;
      this.isCreate = this.$route.params.id === "create";

      if (!this.isCreate) {
        this.addressSet = await addressSetService.get(this.$route.params.id);
        this.alreadySelectedParties = [
          ...this.addressSet.selectedParties,
          ...(await addressSetService.getSelectedParties()),
        ];
        this.isDirty = false;
      } else {
        this.alreadySelectedParties = await addressSetService.getSelectedParties();
      }

      this.parties = (
        await partyService.search(true, "", 5, partyStatus.CONNECTED, {
          sortDesc: [false],
          page: 1,
          itemsPerPage: 100,
          sortBy: "name",
        })
      ).items;
      this.loading = false;
    },
    async performAction(action) {
      if (action === ACTION_DELETE) {
        try {
          await addressSetService.delete(this.addressSet.id);
          this.$router.push(`/admin/address`);
        } catch (err) {
          this.$root.$snackbar.exception(err);
        }
      }
      if (action === ACTION_SAVE) {
        if (!this.isDirty) {
          this.$root.$snackbar.message("There are no changes to save.");
          return;
        }
        this.save();
      }
    },

    async save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;

      try {
        if (this.isCreate) {
          await addressSetService.create(this.addressSet);
        } else {
          await addressSetService.update(this.addressSet);
        }
      } catch (ex) {
        this.loading = false;
        this.$root.$snackbar.exception(ex);
        return;
      }

      this.$root.$snackbar.message(`Address set ${this.isCreate ? "Created" : "Saved"}`);
      this.$router.push(`/admin/address`);
    },

    cancel() {
      this.$router.push(`/admin/address`);
    },

    paymentAddressSameAsMainClick() {
      this.resetAddressSet();
    },

    taxAddressSameAsMainClick() {
      this.resetAddressSet();
    },

    syncAddressSet() {
      if (this.addressSet.paymentAddressSameAsMain) {
        this.addressSet.paymentAddressLineOne = this.addressSet.mainAddressLineOne;
        this.addressSet.paymentAddressLineTwo = this.addressSet.mainAddressLineTwo;
        this.addressSet.paymentAddressCity = this.addressSet.mainAddressCity;
        this.addressSet.paymentAddressState = this.addressSet.mainAddressState;
        this.addressSet.paymentAddressZipCode = this.addressSet.mainAddressZipCode;

        this.ADDRESS_SET_FIELDS.find(x => x.attribute === "paymentAddressLineOne").readonly = true;
        this.ADDRESS_SET_FIELDS.find(x => x.attribute === "paymentAddressLineTwo").readonly = true;
        this.ADDRESS_SET_FIELDS.find(x => x.attribute === "paymentAddressCity").readonly = true;
        this.ADDRESS_SET_FIELDS.find(x => x.attribute === "paymentAddressState").readonly = true;
        this.ADDRESS_SET_FIELDS.find(x => x.attribute === "paymentAddressZipCode").readonly = true;
      }

      if (this.addressSet.taxAddressSameAsMain) {
        this.addressSet.taxAddressLineOne = this.addressSet.mainAddressLineOne;
        this.addressSet.taxAddressLineTwo = this.addressSet.mainAddressLineTwo;
        this.addressSet.taxAddressCity = this.addressSet.mainAddressCity;
        this.addressSet.taxAddressState = this.addressSet.mainAddressState;
        this.addressSet.taxAddressZipCode = this.addressSet.mainAddressZipCode;

        this.ADDRESS_SET_FIELDS.find(x => x.attribute === "taxAddressLineOne").readonly = true;
        this.ADDRESS_SET_FIELDS.find(x => x.attribute === "taxAddressLineTwo").readonly = true;
        this.ADDRESS_SET_FIELDS.find(x => x.attribute === "taxAddressCity").readonly = true;
        this.ADDRESS_SET_FIELDS.find(x => x.attribute === "taxAddressState").readonly = true;
        this.ADDRESS_SET_FIELDS.find(x => x.attribute === "taxAddressZipCode").readonly = true;
      }
    },

    resetAddressSet() {
      if (!this.addressSet.paymentAddressSameAsMain) {
        this.addressSet.paymentAddressLineOne = "";
        this.addressSet.paymentAddressLineTwo = "";
        this.addressSet.paymentAddressCity = "";
        this.addressSet.paymentAddressState = "";
        this.addressSet.paymentAddressZipCode = "";

        this.ADDRESS_SET_FIELDS.find(x => x.attribute === "paymentAddressLineOne").readonly = false;
        this.ADDRESS_SET_FIELDS.find(x => x.attribute === "paymentAddressLineTwo").readonly = false;
        this.ADDRESS_SET_FIELDS.find(x => x.attribute === "paymentAddressCity").readonly = false;
        this.ADDRESS_SET_FIELDS.find(x => x.attribute === "paymentAddressState").readonly = false;
        this.ADDRESS_SET_FIELDS.find(x => x.attribute === "paymentAddressZipCode").readonly = false;
      }

      if (!this.addressSet.taxAddressSameAsMain) {
        this.addressSet.taxAddressLineOne = "";
        this.addressSet.taxAddressLineTwo = "";
        this.addressSet.taxAddressCity = "";
        this.addressSet.taxAddressState = "";
        this.addressSet.taxAddressZipCode = "";

        this.ADDRESS_SET_FIELDS.find(x => x.attribute === "taxAddressLineOne").readonly = false;
        this.ADDRESS_SET_FIELDS.find(x => x.attribute === "taxAddressLineTwo").readonly = false;
        this.ADDRESS_SET_FIELDS.find(x => x.attribute === "taxAddressCity").readonly = false;
        this.ADDRESS_SET_FIELDS.find(x => x.attribute === "taxAddressState").readonly = false;
        this.ADDRESS_SET_FIELDS.find(x => x.attribute === "taxAddressZipCode").readonly = false;
      }
    },
  },
  computed: {
    ...mapGetters("auth0Store", ["selectedCompanyId", "auth0Token"]),
    enhancedParties: function() {
      return this.parties.map(x => ({
        isSelectable: !this.alreadySelectedParties.some(p => p.id === x.id),
        ...x,
      }));
    },
  },
  watch: {
    addressSet: {
      handler: function () {
        this.syncAddressSet();
        this.isDirty = true;
      },
      deep: true,
    },
  },
};
</script>
