import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    chartData: Array,
    chartLabels: Array,
    onClick: Function,
  },
  async mounted() {
    const chart = {
      data: {
        datasets: [
          {
            data: this.chartData,
            backgroundColor: ["#4facd1", "#4facd1", "#229d22", "#229d22", "#d22", "#4facd1"],
            label: "Invoices",
          },
        ],
        labels: this.chartLabels,
      },
      options: {
        onClick: (event, barInfo) => {
          if (this.onClick) this.onClick(barInfo[0]._index);
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                stepSize: 1,
              },
            },
          ],
        },
      },
    };

    this.renderChart(chart.data, chart.options);
  },
};
