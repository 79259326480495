import httpService from "./httpService";
import ErrorStackParser from "error-stack-parser";

const axiosErrorToStackTrace = error => ({
  fileName: "API REST call",
  functionName: error.config.url,
  source: JSON.stringify(error.response),
});

export default {
  logException: (error, message, source = "Exchange UI") => {
    let stackTrace, url, type;
    if (error.isAxiosError) {
      stackTrace = [axiosErrorToStackTrace(error)];
      url = error.config.url;
      type = "api REST call exception";
    } else {
      stackTrace = ErrorStackParser.parse(error);
      type = "javascript error";
      url = source;
    }
    httpService.post("/clientexceptionlogger/exception", { message, stackTrace, type, cause: source, url });
  },
  logAuthException: (error, message, source = "Exchange UI") => {
    //authenticationexceptionlogger
    let stackTrace, url, type;
    if (error.isAxiosError) {
      stackTrace = [axiosErrorToStackTrace(error)];
      url = error.config.url;
      type = "api REST call exception";
    } else {
      stackTrace = ErrorStackParser.parse(error);
      type = "javascript error";
      url = source;
    }
    httpService.postWithNoCompanyId("/authenticationexceptionlogger/exception", { message, stackTrace, type, cause: source, url });
  }
};
