<template>
  <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }">
    <v-card>
      <v-card-text class="title pt-4">{{ title }}</v-card-text>
      <v-card-text>
        {{ text }}
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click.native="ok">{{ buttonText }}</v-btn>
        <v-btn color="grey" text @click.native="cancel" v-show="!options.hideCancel">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    title: "",
    text: "",
    buttonText: "",
    options: {
      color: "red",
      width: 390,
      zIndex: 200,
    },
  }),
  methods: {
    open(title, text, buttonText, options) {
      this.dialog = true;
      this.title = title;
      this.text = text;
      this.buttonText = buttonText || "OK";
      this.options = Object.assign(this.options, options || {});
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    ok() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      if (this.options.hideCancel) return;
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
