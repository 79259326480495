import httpService from "@/services/httpService";
import pagingService from "./pagingService";

export default {
  search: async (mustBeActive, query, partyTypeId, partyStatus, options, filterVendorsOnly = false) => {
    return httpService.get(
      `/party/grid/search?${
        mustBeActive ? "isActive=true&" : ""
      }partyStatus=${partyStatus}&${pagingService.toQueryString(options)}&query=${query || ""}&partyTypeId=${
        partyTypeId === -1 ? "" : partyTypeId
      }${filterVendorsOnly ? "&filterVendorsOnly=true" : ""}`
    );
  },
  get: async id => httpService.get(`/party/${id}`),
  connect: async id => httpService.put(`/party/${id}/connect`),
  invite: async id => httpService.put(`/party/${id}/invite`),
  create: async party => httpService.post("/party", party),
  update: async party => httpService.put(`/party/${party.id}`, party),
  delete: async id => httpService.deleteRequest(`/party/${id}`),
  networkSearch: async () => httpService.post("/party/networksearch"),
  getPartyTypesByCompanyId: async companyId => httpService.get(`/party/partyTypesByCompanyId/${companyId}`),

  formatAddress: party => {
    if (!party) return "";

    var result = [];

    var addIfDefined = function(input) {
      if (input && input !== "NULL") result.push(input);
    };

    addIfDefined(party.addressLineOne);
    addIfDefined(party.city);
    addIfDefined(party.state);

    return result.join(", ");
  },
};
