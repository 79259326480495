<template>
  <div>
    <HistoryDetailDialog
      :title="historyDetailTitle"
      :show="historyDetails.length > 0"
      :documentMetadata="documentMetadata"
      :lineItemMetadatas="lineItemMetadatas"
      :historyDetails="historyDetails"
      :close="() => (historyDetails = [])"
    />

    <v-tabs background-color="transparent" grow class="pl-4" v-model="selectedView">
      <v-tab>All</v-tab>
      <v-tab>Updates</v-tab>
      <v-tab>Workflow</v-tab>
      <v-tab>Views</v-tab>
    </v-tabs>

    <v-list three-line color="transparent">
      <template v-for="(history, index) in filteredHistory">
        <v-divider v-if="index > 0" :key="`divider-${history.id}`" inset></v-divider>

        <v-list-item :key="`history-${history.id}`">
          <v-list-item-avatar>
            <v-icon>mdi-{{ getIcon(history) }}</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              <span v-if="!history.hasHistoryDetails">{{ getTitle(history) }}</span>
              <a
                @click="showDetails(history.objectId, history)"
                href="javascript:void(0)"
                v-if="history.hasHistoryDetails"
                >{{ getTitle(history) }}</a
              >
            </v-list-item-title>
            <v-list-item-subtitle v-html="getDate(history)"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
import historyService from "@/services/historyService";
import dateService from "@/services/dateService";
import HistoryDetailDialog from "@/dialogs/HistoryDetailDialog.vue";
import { mapState } from "vuex";

export default {
  name: "DocumentHistory",

  props: {
    history: Array,
    documentMetadata: Array,
    lineItemMetadatas: Array,
  },

  components: {
    HistoryDetailDialog,
  },

  data() {
    return {
      selectedView: 0,
      historyDetails: [],
      historyDetailTitle: "",
    };
  },

  computed: {
    ...mapState("userProfile", ["dateFormat"]),

    filteredHistory() {
      if (this.selectedView === 0) return this.history;

      const views = {
        0: "All",
        1: "Edit",
        2: "Workflow",
        3: "Read",
      };

      let viewName = views[this.selectedView];
      return this.history.filter(h => h.operation.categories.indexOf(viewName) >= 0);
    },
  },

  methods: {
    async showDetails(documentId, history) {
      this.historyDetailTitle = this.getTitle(history);
      this.historyDetails = await historyService.getHistoryDetailsForDocument(documentId, history.id);
    },

    getIcon(history) {
      return historyService.getIcon(history);
    },

    getTitle(history) {
      return `${historyService.formatHistoryOperation(history)} by ${history.updateUser}`;
    },

    getDate(history) {
      return dateService.formatDateTime(history.updateDate, this.dateFormat);
    },
  },
};
</script>
