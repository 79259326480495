import httpService from "./httpService";

export default {
  search: async () => httpService.get("/documentroute/"),
  getDocumentStats: async () => httpService.get("/documentroute/stats"),
  getSentDocuments: async () => httpService.get("/documentroute/sent"),
  getInboxDocuments: async () => httpService.get("/documentroute/inbox"),
  getForDocumentType: async (documentTypeId, documentRouteStatusId) =>
    httpService.get(
      `/documentroute/all/${documentTypeId}${
        documentRouteStatusId > 0 ? "?documentRouteStatusId=" + documentRouteStatusId : ""
      }`
    ),
  getNotReturnedDivisionOrders: async () => httpService.get("/documentroute/notReturnedDOs"),
  getOwnerDocumentReceivedCountsPerDay: async () => httpService.get("/documentroute/ownerDocReceivedCounts"),
  getOwnerDocumentSentCountsPerDay: async () => httpService.get("/documentroute/ownerDocSentCounts"),
};
