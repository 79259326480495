import httpService from "../httpService";

export default {
  getList: async (mustBeActive, page, pageSize) =>
    httpService.get(
      `/addressset?${mustBeActive ? "isActive=true&" : ""}page=${page || 1}&pageSize=${pageSize || 10000}`
    ),
  get: async id => httpService.get(`/addressset/${id}`),
  getSelectedParties: async () => httpService.get("/addressset/parties"),
  create: async addressSet => httpService.post("/addressset", addressSet),
  update: async addressSet => httpService.put(`/addressset/${addressSet.id}`, addressSet),
  delete: async id => httpService.deleteRequest(`/addressset/${id}`),
};
