import moment from "moment";
import documentMetadataService from "./documentMetadataService";
import _ from "lodash";
//import DocumentType from "../constants/documentType";
import metadataKey from "../constants/metadataKey";

export default {
  isValidDateRange: (start, end) => moment(start).isBefore(end),
  isvalidEmail: email =>
    email &&
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email.toLowerCase()
    ),
  textToNumber: args =>
    args
      .replace(/\$/g, "")
      .replace(/,/g, "")
      .replace(/[^0-9\-.]+/g, ""),

  onlyAllowNumber: evt => {
    evt = evt ? evt : window.event;
    let charCode = evt.which ? evt.which : evt.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46 && charCode !== 45) {
      evt.preventDefault();
    } else {
      return true;
    }
  },
  validateDocument: document => {
    let optionalFields = [
      metadataKey.DivisionOrderAddressLineOne,
      metadataKey.DivisionOrderAddressLineTwo,
      metadataKey.DivisionOrderCity,
      metadataKey.DivisionOrderState,
      metadataKey.DivisionOrderZipCode
    ];
	let requiredSystemFields = [
      metadataKey.InvoiceInvoiceNumber,
      metadataKey.InvoiceNumber,
      metadataKey.InvoiceServiceDate,
      metadataKey.InvoiceInvoiceTotal,
      metadataKey.InvoiceScanDate,
      metadataKey.InvoiceOrgUnitName,
      metadataKey.InvoiceQuantity
    ];
    const documentTypesToValidateAllColumns = [];
    const shouldValidateAllColumns = documentTypesToValidateAllColumns.includes(document.documentTypeId);
    let generateErrorMessage = emptyFields =>
      emptyFields.length > 1 ? `Fields ${emptyFields.join(", ")} are required` : `Field ${emptyFields[0]} is required`;

    // --- validate required fields in the header
    let fieldsToValidate = shouldValidateAllColumns
      ? document.metadata
      : document.metadata.filter(x => (x.metadata.isThirdParty && !optionalFields.includes(x.metadata.key)) || (requiredSystemFields.includes(x.metadata.key)));
    let emptyFields = fieldsToValidate.filter(x => !x.value).map(x => x.metadata.name);

    if (emptyFields.length > 0) return { isValid: false, errorMessage: generateErrorMessage(emptyFields) };

    // --- validate required fields in line items
    let hasEnteredAnyLineItems = documentMetadataService.hasAnyLineItems(document.lineItems);
    if (hasEnteredAnyLineItems) {
      emptyFields = document.lineItems.map(r =>
        r.cells.filter(c => (shouldValidateAllColumns || c.metadata.isThirdParty) && !c.value).map(x => x.metadata.name)
      );
      let uniqueEmptyDetailFields = _.uniq(_.flatten(emptyFields));
      if (uniqueEmptyDetailFields.length > 0) {
        return { isValid: false, errorMessage: generateErrorMessage(uniqueEmptyDetailFields) };
      }
    }
    return { isValid: true, errorMessage: null };
  },
};
