<template>
  <v-container fluid v-if="orgUnit">
    <v-card class="elevation-1 card pa-4 card-form">
      <v-form ref="form">
        <v-card-text>
          <v-row>
            <h3 class="headline mb-4">{{ isCreate ? "Create" : "Edit" }} {{ orgUnitTerminology }}</h3>
            <v-spacer />
            <div v-show="!isCreate">
              <ActionDropdown :actions="actions" :performAction="performAction" />
            </div>
          </v-row>
          <EntityForm :entity="orgUnit" :inputFields="ORGUNIT_FIELDS" />
        </v-card-text>
      </v-form>

      <v-card-actions>
        <v-spacer />
        <v-btn @click="save()" :disabled="loading" large color="primary" depressed>Save</v-btn>
        <v-btn @click="cancel()" :disabled="loading" large depressed>Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import orgUnitService from "@/services/admin/orgUnitService";
import EntityForm from "@/components/EntityForm";
import ActionDropdown from "@/components/ActionDropdown";
import Auth0Mixin from "../Auth0Mixin";
import terminologyService from "@/services/terminologyService.js";
import terminology from "@/constants/terminology";

const ACTION_DELETE = "Delete";
const ACTION_SAVE = "Save";

const requiredRule = [v => !!v || "Required"];

const ORGUNIT_FIELDS = [
  { label: "Name", attribute: "name", rules: requiredRule, type: "text" },
  {
    label: "Code",
    attribute: "code",
    rules: requiredRule,
    type: "text",
    altText:
      "Used to quickly separate and identify your org unit.  This can be anything that is used internally within your company do identify this division, or as simple as the word 'Default'",
  },

  // Contact
  { label: "Phone number", attribute: "phone", type: "text" },

  // Address
  { label: "Address", attribute: "address", type: "header" },
  { label: "Address Line One", attribute: "addressLineOne", type: "text" },
  { label: "Address Line Two", attribute: "addressLineTwo", type: "text" },
  { label: "City", attribute: "city", type: "text" },
  { label: "State", attribute: "state", type: "text" },
  { label: "Zip Code", attribute: "zipCode", type: "text" },

  { label: "Active", attribute: "isActive", type: "checkbox" },
];

export default {
  name: "OrgUnit",
  mixins: [Auth0Mixin],
  components: { EntityForm, ActionDropdown },
  data() {
    return {
      orgUnit: null,
      headers: [{ text: "Name", value: "name" }],
      ORGUNIT_FIELDS,
      isCreate: false,
      loading: false,
      actions: [ACTION_SAVE, ACTION_DELETE],
      requiredRules: [v => !!v || "Required"],
      orgUnitTerminology: terminologyService.lookup(terminology.ORG_UNIT),
    };
  },

  methods: {
    async init() {
      this.isCreate = this.$route.params.id === "create";

      if (this.isCreate) {
        this.orgUnit = {
          isActive: true,
        };
      } else {
        this.orgUnit = await orgUnitService.get(this.$route.params.id);
      }
    },

    async performAction(action) {
      if (action === ACTION_DELETE) {
        try {
          await orgUnitService.delete(this.orgUnit.id);
          this.$router.push(`/admin/orgunits`);
        } catch (err) {
          this.$root.$snackbar.exception(err);
        }
      }
      if (action === ACTION_SAVE) {
        this.save();
      }
    },

    async save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;

      try {
        if (this.isCreate) {
          await orgUnitService.create(this.orgUnit);
        } else {
          await orgUnitService.update(this.orgUnit);
        }
      } catch (ex) {
        this.loading = false;
        this.$root.$snackbar.exception(ex);
        return;
      }

      this.$root.$snackbar.message(`${this.orgUnitTerminology} ${this.isCreate ? "Created" : "Saved"}`);
      this.$router.push(`/admin/orgunits`);
    },
    cancel() {
      this.$router.push(`/admin/orgunits`);
    },
  },
};
</script>
