import { environment } from "./env";

const autoConfig = {
  domain: "auto-wolfepak.us.auth0.com",
  clientId: "ngHrHhndJhHd3PXIDuX2dnYkj2a1SyZg",
  audience: "https://cloud.docvue/api",
};

const devConfig = {
  domain: "dev-wolfepak.us.auth0.com",
  clientId: "d53GB7w2ELDF02ZeJJbFNIRYRdfV3juV",
  audience: "https://cloud.docvue/api",
};

const prodConfig = {
  domain: "prod-wolfepak.us.auth0.com",
  clientId: "ILb2wbKlGFqKYJZwMTzYolM4D65A1wWp",
  audience: "https://cloud.docvue/api",
};

const qaConfig = {
  domain: "qa-wolfepak.us.auth0.com",
  clientId: "KQQj38Xmf51Lrny1Onrkb1PB9dK3DxOW",
  audience: "https://cloud.docvue/api",
};

const preConfig = {
  domain: "pre-wolfepak.us.auth0.com",
  clientId: "ol247PihJie4RITASqWgReAp2NPNF8ug",
  audience: "https://cloud.docvue/api",
};

const uatConfig = {
  domain: "uat-wolfepak.us.auth0.com",
  clientId: "oaSWc98eQeQPjnwnlscujPO5pgRX239W",
  audience: "https://cloud.docvue/api",
};

const config = {
  auto: autoConfig,
  dev: devConfig,
  qa: qaConfig,
  uat: uatConfig,
  pre: preConfig,
  prod: prodConfig,
};

let currentConfig = config[environment];

export default {
  audience: currentConfig.audience,
  clientId: currentConfig.clientId,
  domain: currentConfig.domain,
  eulaVersion: 20230112090913,
};
