<template>
  <div v-show="initialized">
    <v-row>
      <v-col cols="12">
        <v-card class="pa-4 ma-2">
          <v-card-title class="headline">EULA</v-card-title>
          <v-card-text>
            <p>
              BY CLICKING THE ACCEPT BUTTON BELOW, OR BY DOWNLOADING, INSTALLING, OR OTHERWISE USING THE DOCVUE
              PRODUCTS, YOU AGREE TO BE BOUND BY THIS END-USER LICENSE AGREEMENT.
            </p>
            <p>
              More specifically, you will not (i) use this access in violation of applicable laws or of any rules,
              regulations, or guidelines imposed by your employer (DocVue’s Customer); (ii) send or store infringing,
              obscene, threatening, or otherwise unlawful or tortious material, including material that violates privacy
              rights in connection with this access; (iii) send or store malicious code in connection with this access;
              (iv) damage, disable, overburden, impair, interfere with, or disrupt DocVue’s cloud-based hosted
              environment: (v) attempt to gain unauthorized access to any systems or networks that connect thereto,
              purloin or decompile or reverse engineer items therein, or otherwise interfere with DocVue’s operation or
              that of its hosted environment or in any way with the use or enjoyment of the environment by others; (vi)
              facilitate access by more users than are permitted to Customer; (vii) allow more than one individual to
              use a user account; (viii) provide access to any third party (via any services arrangement, service
              bureau, lease, sale, resale, or otherwise); and, (ix) exceed any applicable bandwidth limit or storage
              capacity limit, if any. Furthermore, you agree to receive any/all tax documents from the sending company 
              until such time that you are no longer affiliated with the company.
            </p>
            <p>
              In exchange, DocVue hereby grants you a non-exclusive, non-transferable license without rights to
              sublicense to access this site and to use the within Products and supporting documentation solely for your
              employer’s authorized internal business purposes.
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="accept">Accept</v-btn>
            <v-btn v-on:click="cancel">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import userService from "@/services/userService";
import Auth0Mixin from "./Auth0Mixin";
import { getClaimName } from "../auth/claims";

export default {
  name: "Eula",
  mixins: [Auth0Mixin],
  props: ["redirectTo"],
  data() {
    return {
      initialized: false,
    };
  },
  methods: {
    async init() {
      this.initialized = true;
    },
    async accept() {
      let user = await userService.acceptEula(this.$auth.user.sub);
      const eula = getClaimName("acceptedEulaVersion");
      this.$auth.user[eula] = user.eulaVersion;
      this.$router.push({ path: this.redirectTo || "/" });
    },
    async cancel() {
      await this.$auth.logout();
    },
  },
};
</script>
