<template>
  <div class="docvue-background">
    <img src="/img/landing-page.jpg" style="width: 100%" />
    <div>
      <v-row class="pt-4 pb-4 login-banner" align="center" justify="center">
        <v-btn x-large data-cy="login-button" color="secondary" @click="login">Login</v-btn>
        <v-btn x-large color="primary" class="ml-4" @click="signup">Sign up</v-btn>
      </v-row>
    </div>
  </div>
</template>

<script>
import { getInstance } from "../auth/authWrapper";
import Auth0Mixin from "./Auth0Mixin";

export default {
  name: "LandingPage",
  mixins: [Auth0Mixin],
  data() {
    return {};
  },
  props: ["redirectTo"],
  methods: {
    login() {
      const authService = getInstance();
      authService.loginWithRedirect({ appState: { targetUrl: this.redirectTo || "/" } });
    },
    signup() {
      this.$router.push({ path: "/register" });
    },

    async init() {
      let redirectTo = this.$route.query.redirectTo;

      // force login if redirect URL set
      if (redirectTo) {
        const authService = getInstance();
        authService.loginWithRedirect({ appState: { targetUrl: redirectTo } });
      }
    },
  },
};
</script>
<style scoped></style>
