<template>
  <v-container fluid v-if="user && preferences">
    <v-card class="elevation-1 card pa-4 card-form">
      <v-form ref="form">
        <v-card-text>
          <v-row>
            <h3 class="headline mb-4 ml-2">Profile</h3>
            <v-spacer />
          </v-row>
          <EntityForm :entity="user" :inputFields="profileFields" />
        </v-card-text>

        <v-card-text>
          <v-row>
            <h3 class="headline mb-4 ml-2">Preferences</h3>
            <v-spacer />
          </v-row>
          <EntityForm :entity="preferences" :inputFields="preferencesFields" />
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="save" :disabled="loading" large color="primary" depressed>Save</v-btn>
        <v-btn @click="cancel" :disabled="loading" large depressed>Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import EntityForm from "@/components/EntityForm";
import Auth0Mixin from "./Auth0Mixin";
import userService from "@/services/userService";

let dateFormatOptions = ["MM/dd/yyyy", "dd/MM/yyyy", "yyyy/MM/dd", "yyyy-MM-dd"].map(x => ({
  name: x.toUpperCase(),
  id: x,
}));

const PREFERENCES_FIELDS = [
  { label: "Date Format", attribute: "dateFormat", type: "select", items: dateFormatOptions },
  { label: "Receive Emails", attribute: "receiveEmails", type: "checkbox" },
];

const PROFILE_FIELDS = [
  { label: "Email Address", attribute: "emailAddress", type: "text", readonly: true },
  { label: "First Name", attribute: "firstName", type: "text" },
  { label: "Last Name", attribute: "lastName", type: "text" },
  { label: "Title", attribute: "title", type: "text" },
];

export default {
  name: "Profile",
  mixins: [Auth0Mixin],
  components: {
    EntityForm,
  },
  props: {
    unsubscribeEmail: Boolean,
  },
  data() {
    return {
      loading: false,
      user: {},
    };
  },
  computed: {
    preferences() {
      var preferences = this.$store.getters["userProfile/profile"];
      if (this.unsubscribeEmail) preferences.receiveEmails = false;
      return preferences;
    },
    preferencesFields() {
      var fields = [...PREFERENCES_FIELDS];
      fields[0].disabled = !!this.unsubscribeEmail;
      return fields;
    },
    profileFields() {
      let fields = [...PROFILE_FIELDS];
      fields.forEach(x => (x.disabled = !!this.unsubscribeEmail));
      return fields;
    },
  },

  methods: {
    async init() {
      this.user = await userService.getCurrent();
    },

    save() {
      this.$store.dispatch("auth0Store/saveUserProfile", this.user);
      this.$store.dispatch("userProfile/saveUserPreferences", this.preferences);
      this.$root.$snackbar.message("Successfully updated settings");
      this.$router.push({ name: "Home" });
    },
    cancel() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>
