import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { Auth0Plugin } from "./auth";
import vuetify from "./plugins/vuetify";
import "@babel/polyfill";
import auth0Config from "./auth0Config";
import { VueMaskDirective } from "v-mask";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueDragResize from "vue-drag-resize";
import VueGtag from "vue-gtag";
import { environment } from "./env";

Vue.directive("mask", VueMaskDirective);
Vue.config.productionTip = false;
Vue.use("vue-moment");

Vue.component("vselect", vSelect);
Vue.component("vue-drag-resize", VueDragResize);

Vue.use(Auth0Plugin, {
  domain: auth0Config.domain,
  clientId: auth0Config.clientId,
  audience: auth0Config.audience,
  onRedirectCallback: appState => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  },
});

if (environment === "prod") {
  Vue.use(VueGtag, {
    config: { id: "G-0KDVX92HLN" },
  });
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount("#app");
