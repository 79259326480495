<template>
  <v-flex class="ma-4">
    <v-tabs background-color="transparent" v-model="selectedTab" class="mb-4">
      <v-tab key="legalDoccuments" to="/admin/settings/legalDocuments" exact>Company Documents</v-tab>
      <v-tab key="companyLogo" to="/admin/settings/companyLogo" exact>Logo</v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab" style="background: transparent">
      <v-tab-item
        :transition="false"
        :reverse-transition="false"
        key="legalDoccuments"
        value="/admin/settings/legalDocuments"
      >
        <router-view></router-view>
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false" key="companyLogo" value="/admin/settings/companyLogo">
        <router-view></router-view>
      </v-tab-item>
    </v-tabs-items>
  </v-flex>
</template>

<script>
import Auth0Mixin from "../Auth0Mixin";

export default {
  name: "Settings",
  mixins: [Auth0Mixin],
  data() {
    return {
      selectedTab: "/admin/settings/legalDocuments",
    };
  },
  methods: {
    async init() {},
  },
};
</script>
