<template>
  <div>
    <AttachmentDialog
      :v-if="!isReadonly && selectedAttachment !== null"
      :show="showAttachmentDialog"
      :attachment="selectedAttachment"
      :close="closeAttachmentPreview"
    />
    <div class="pa-4">
      <FileUploader
        v-if="!isReadonly"
        label="Click to upload an attachment"
        :url="url"
        :fileUploadData="fileUploadData"
        :success="uploadFinished"
        :onFinished="uploadFinished"
        :onBeforeSending="beforeSending"
      />
      <v-progress-linear indeterminate v-if="isBusy"></v-progress-linear>
    </div>

    <v-list three-line color="transparent">
      <v-subheader>
        <h3>Attachments</h3>
      </v-subheader>
      <v-subheader v-if="attachments.length === 0">
        This document has no attachments.
      </v-subheader>
      <template v-for="(attachment, index) in attachments">
        <v-divider v-if="index > 0" :key="`divider-${attachment.id}`" inset></v-divider>

        <v-list-item :key="`attachment-${attachment.id}`">
          <v-list-item-avatar rounded color="grey lighten-2">
            <a v-on:click="download(attachment)">
              <v-icon>mdi-download</v-icon>
            </a>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              <a v-on:click="previewAttachment(attachment)">
                {{ getTitle(attachment) }}
              </a>
            </v-list-item-title>
            <v-list-item-subtitle> {{ getFileSize(attachment) }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <a v-if="!isReadonly" v-on:click="deleteAttachment(attachment)">
              <v-icon>mdi-delete</v-icon>
            </a>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
import { API_URL } from "../env";
import downloadService from "@/services/downloadService";
import attachmentService from "@/services/attachmentService";
import FileUploader from "@/components/FileUploader";
import AttachmentDialog from "@/dialogs/AttachmentDialog";
import filesize from "filesize";
import store from "@/store";
import { mapState, mapGetters } from "vuex";

export default {
  name: "DocumentAttachments",
  props: {
    attachments: Array,
    documentId: Number,
    isReadonly: Boolean,
    onAttachmentsChanged: Function,
  },
  components: { FileUploader, AttachmentDialog },

  data() {
    return {
      selectedView: 0,
      url: `/documents/upload`,
      fileUploadData: { documentId: this.documentId, isAttachment: true },
      selectedAttachment: null,
      showAttachmentDialog: false,
    };
  },

  computed: {
    ...mapState("appStore", ["isBusy"]),
    ...mapGetters("auth0Store", ["selectedCompanyId", "auth0Token"]),
  },

  methods: {
    getTitle(attachment) {
      return attachment.fileName;
    },
    getFileSize(attachment) {
      return filesize(attachment.fileSize);
    },
    download(attachment) {
      const url = `${API_URL}/download/attachment/${attachment.id}`;
      downloadService.download(url, this.selectedCompanyId, this.auth0Token, attachment.fileName);
    },
    async deleteAttachment(attachment) {
      if (await this.$root.$confirmDialog.open("Delete", `Delete ${this.getTitle(attachment)} ?`, "Delete")) {
        await attachmentService.deleteAttachment(attachment.id);
        await this.onAttachmentsChanged();
      }
    },
    previewAttachment(attachment) {
      this.selectedAttachment = attachment;
      this.showAttachmentDialog = true;
    },
    closeAttachmentPreview() {
      this.selectedAttachment = null;
      this.showAttachmentDialog = false;
    },
    beforeSending() {
      store.commit("appStore/isBusy", true);
    },
    async uploadFinished() {
      store.commit("appStore/isBusy", false);
      await this.onAttachmentsChanged();
    },
  },
};
</script>
