<template>
  <div>
    <div class="docvue-background">
      <img src="/img/landing-page.jpg" />
    </div>
    <v-flex style="margin-top: 7rem">
      <v-card class="elevation-1 card mt-4 pa-4 card-form justify-center" style="max-width: 800px; margin: 500px auto">
        <h3 class="display-1 mb-4 pb-4">Error loading the application</h3>
        <div class="text-center pt-4 mt-4 mb-4 pb-4">
          We are sorry, an error occured while loading the application. Please try to logout and login again, and please report this to us to solve it for the future.
        </div>
        <div>
          <v-spacer />
          <v-btn @click="logout()" large color="primary" block x-large depressed>
            Logout
          </v-btn>
        </div>
      </v-card>
    </v-flex>
  </div>
</template>

<script>
import localStorageService from "@/services/localStorageService.js";
import auth0Config from "@/auth0Config";
import { URL } from "@/env";

export default {
  name: "ErrorLoading",
  methods: {
    async logout() {
      localStorageService.clear();
      await this.$auth.logout();
      const encodedUrl = encodeURIComponent(URL);
      window.location.href = `https://${auth0Config.domain}/v2/logout?client_id=${auth0Config.clientId}&returnTo=${encodedUrl}`;
    },
  },
};
</script>
