<template>
  <div class="pa-2">
    <SuggestDetailItemsPanel
      :show="showSuggestDetailItemsPanel"
      :headers="headers"
      :close="closeSuggestedRowPanel"
      :rows="suggestedRows"
    />
    <div v-show="!showSuggestDetailItemsPanel">
      <v-row class="ml-0 mr-0">
        <div v-if="proposedLineItems.suggestedRows.length > 0" class="pt-2">
          <v-btn depressed color="primary" class="ml-2" text @click="showSuggestDetailItemsPanel = true">
            <v-icon left>mdi-auto-fix</v-icon>
            {{ proposedLineItems.suggestedRows.length }} suggested {{ hasSuggestedRowsMoreThanOne ? "rows" : "row" }}
          </v-btn>
        </div>
        <v-btn class="ml-4 mt-2 mb-2" depressed @click="addRow" :disabled="disableAddAndDeleteRows">
          <v-icon left>mdi-plus</v-icon>
          Add row</v-btn
        >
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>

      <v-data-table
        :height="height"
        fixed-header
        disable-pagination
        disable-sort
        :hide-default-footer="true"
        :no-data-text="''"
        :headers="headers"
        :items="rows"
        :loading="loading"
      >
        <template v-for="(slot, columnIndex) in headers" v-slot:[`item.${slot.value}`]="{ item }">
          <DocumentMetadata
            :isLineItem="true"
            :textSelection="textSelection"
            :documentMetadata="item[slot.value]"
            :key="slot.value"
            :focusOnLoad="columnIndex - 1 === focusColumnIndex && item.index === focusRowIndex"
            :onClickToFill="() => onClickToFill(item.index, columnIndex)"
          />
        </template>

        <template v-slot:item.icon="{ item }">
          <v-icon @click="deleteRow(item.index)" :disabled="disableAddAndDeleteRows">mdi-delete</v-icon>
        </template>
      </v-data-table>

      <v-row class="mt-4 ml-0 mr-0">
        <v-btn class="ml-4" @click="addRow" depressed :disabled="disableAddAndDeleteRows">
          <v-icon left>mdi-plus</v-icon>
          Add row</v-btn
        >
      </v-row>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import DocumentMetadata from "@/components/DocumentMetadata";
import SuggestDetailItemsPanel from "@/components/SuggestDetailItemsPanel";
import documentMetadataService from "@/services/documentMetadataService";

export default {
  name: "DetailItems",
  props: {
    id: String,
    proposedLineItems: Object,
    textSelection: Object,
    addRow: Function,
    deleteRow: Function,
    close: Function,
    height: Number,
    dollarTotal: Number,
  },
  components: { DocumentMetadata, SuggestDetailItemsPanel },

  data() {
    return {
      loading: false,
      headers: [],
      rows: [],
      suggestedRows: [],
      showSuggestDetailItemsPanel: false,
      focusRowIndex: 0,
      focusColumnIndex: 0,
    };
  },

  computed: {
    disableAddAndDeleteRows() {
      return _.every(this.proposedLineItems.rows[0].cells, c => c.metadata.isReadonly);
    },

    detailItemTotal() {
      return documentMetadataService.detailItemTotal(this.proposedLineItems.rows);
    },

    hasSuggestedRowsMoreThanOne() {
      return this.proposedLineItems.suggestedRows && this.proposedLineItems.suggestedRows.length > 1;
    },

    matchDollarTotal() {
      return this.dollarTotal && this.dollarTotal === this.detailItemTotal;
    },
  },

  watch: {
    "proposedLineItems.rows": function() {
      this.rows = this.translateRows(this.proposedLineItems.rows);
    },
  },

  methods: {
    translateRows(rows) {
      let translatedRows = rows.map((r, index) => {
        let row = { index, selected: true };
        r.cells.forEach(cell => {
          row[cell.metadata.key] = cell;
        });
        return row;
      });

      return translatedRows;
    },

    onClickToFill(rowIndex, columnIndex) {
      let isLastColumn = columnIndex === this.headers.length - 1;
      let isLastRow = rowIndex === this.rows.length - 1;

      if (isLastColumn) {
        if (isLastRow) {
          this.addRow();
        }
        this.focusColumnIndex = 0;
        this.focusRowIndex = rowIndex + 1;
      } else {
        this.focusColumnIndex = columnIndex;
        this.focusRowIndex = rowIndex;
      }
    },

    closeSuggestedRowPanel(addRows) {
      this.showSuggestDetailItemsPanel = false;

      if (!addRows) return;

      if (
        this.proposedLineItems.rows.length >= 1 &&
        this.proposedLineItems.rows[0].cells.filter(c => c.value).length === 0
      ) {
        this.rows.splice(0, this.rows.length);
        this.proposedLineItems.rows.splice(0, this.proposedLineItems.rows.length);
      }

      for (let i = 0; i < this.suggestedRows.length; i++) {
        let suggested = this.suggestedRows[i];

        if (suggested.selected) {
          this.rows.push(suggested);
          this.proposedLineItems.rows.push(JSON.parse(JSON.stringify(this.proposedLineItems.suggestedRows[i])));
        }
      }
    },
  },

  async created() {
    this.headers = [
      { text: "", value: "icon", width: "20px", sortable: false, fixed: true },
      ...this.proposedLineItems.columnHeaders.map(h => ({
        text: h.name,
        value: h.key,
        fixed: true,
        width: documentMetadataService.getColumnWidthForMetadata(h),
      })),
    ];

    this.rows = this.translateRows(this.proposedLineItems.rows);
    this.suggestedRows = this.translateRows(this.proposedLineItems.suggestedRows);
    this.loading = false;
  },
};
</script>
<style>
.v-data-table__mobile-row__cell input {
  border: 1px solid #c0c0c0;
}

</style>
